import React, { useEffect, useState } from "react";
import { authenticatedRequest } from "../../../../../../core/services/authenticatedRequest";
import { routes } from "../../../../../../core/constants/routes";
import QRCode from "qrcode";
import { Button } from "../../../../../../components/Misc/Button";

export default function WhatsappComponent({
  organization,
  project,
  updateProject,
}) {
  const [qrDataUrl, setQrDataUrl] = useState("");
  const [whatsapp, setWhatsapp] = useState({});
  const [waiting, setWaiting] = useState(false);
  let updater;

  useEffect(() => {
    getWhatsapp();
    activateWhatsappUpdater();
  }, []);

  function activateWhatsappUpdater() {
    updater = setInterval(getWhatsapp, 3000);
  }

  function deactivateWhatsappUpdater() {
    clearInterval(updater);
  }

  function createWhatsapp() {
    authenticatedRequest({
      method: "post",
      url: routes.api.organizationProjectWhatsapp(organization.id, project.id),
    })
      .then((result) => {
        setWhatsapp(result.data);
        // activateWhatsappUpdater();
      })
      .catch((err) => {
        if (err.response.status === 400) {
          // wa bridge already created
          // still fetch accoutn data, so the qr code will be displayed
          // activateWhatsappUpdater();
        }
      });
  }

  function getWhatsapp() {
    authenticatedRequest({
      method: "get",
      url: routes.api.organizationProjectWhatsapp(organization.id, project.id),
    })
      .then((result) => {
        // if (result.data.qrExpiry)
        // display qr code
        if (result.data?.qr) {
          QRCode.toDataURL(result.data.qr, {}, function (err, url) {
            if (err) throw err;
            setQrDataUrl(url);
            setWaiting(false);
          });
        } else {
          setQrDataUrl("");
        }
        const { bridgeId, jid, chatwootInboxId } = result.data;
        setWhatsapp(result.data);
        updateProject(organization.id, project.id, {
          whatsapp: {
            bridgeId,
            chatwootInboxId,
            jid,
          },
        });
      })
      .catch((error) => {
        if (error.response.status === 404) {
          // no bridge yet
        }
      });
  }

  function resetWhatsapp() {
    setWaiting(true);
    authenticatedRequest({
      method: "post",
      url: routes.api.organizationProjectWhatsappReset(
        organization.id,
        project.id
      ),
    })
      .then((result) => {
        console.log(result);

        // activateWhatsappUpdater();
      })
      .catch((err) => console.error(err));
  }

  if (whatsapp.registered) {
    return (
      <>
        <h4>Whatsapp</h4>

        <p>
          Dein Account (+{whatsapp.jid.split(":")[0]}) wurde erfolgreich
          verbunden.
        </p>
      </>
    );
  }

  if (!whatsapp.bridgeId) {
    return (
      <Button className={"primary"} onClick={createWhatsapp}>
        QR-Code generieren
      </Button>
    );
  }

  if (!qrDataUrl && !waiting) {
    return (
      <Button className={"primary"} onClick={resetWhatsapp}>
        QR-Code generieren
      </Button>
    );
  }

  return (
    <>
      {qrDataUrl ? (
        <img
          id={"image"}
          src={qrDataUrl}
          alt={"qr code, um Whatapp zu verbinden"}
        />
      ) : (
        "QR-Code wird generiert..."
      )}
    </>
  );
}
