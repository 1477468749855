import styled from "styled-components";
import {colors} from "../../core/constants/colors";
import {AppPage} from "../../components/Misc/AppPage";
import {Form} from "../../components/Misc/Form";

export const SettingsStyle = styled(AppPage)`

  ${Form} {
    border-style: none solid solid solid;
  }
  
  .react-tabs__tab-list {
    margin-bottom: 0;
    border-bottom: 1px solid ${colors.greys["200"]};

    li {
      border-radius: 0;
    }

    .react-tabs__tab--selected {
      //border-style: solid;
      //border-width: 1px;
      border-color: ${colors.greys["200"]};
    }
  }
`;
