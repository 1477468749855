import React from "react";
import { connect } from "react-redux";
import { handleLogout } from "../../../core/services/auth";
import ProjectMenu from "./ProjectMenu";
import store from "../../../core/redux/store";
import { fetchOrganizations } from "../../../core/redux/organizations/thunk";

const mapStateToProps = (state, props) => {
  if (!Object.keys(state.organizations).length) {
    store.dispatch(fetchOrganizations());
    return {};
  }

  const organization = state.organizations[Object.keys(state.organizations)[0]];
  const projectId = props.projectId;
  return {
    ...props,
    user: state.auth.user,
    organization: organization,
    projectId: projectId,
    project: organization.projects[projectId],
    location: props.location
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => {
      handleLogout();
    },
  };
};

const ProjectMenuPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(ProjectMenu);

export default ProjectMenuPage;
