const dimensions = {
  mobileWidth: "400px",
  // 1px border
  headerHeight: "calc(55px + 1px)",

  // also change in contenbox.css line 203, line 85 and line 87
  desktopWidth: "1440px",
};

export default dimensions;
