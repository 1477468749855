import { PATCH_USER, SET_USER } from "./action";

const initialState = {
  user: false,
};

export const auth = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER: {
      return {
        user: action.user,
      };
    }

    case PATCH_USER: {
      console.log("state user", state.user)
      console.log("action user", action.patch)
      console.log("result", {
        ...state.user,
        ...action.patch,
      },)
      return {
        user: {
          ...state.user,
          ...action.patch,
        },
      };
    }

    default:
      return state;
  }
};
