import React from "react";
import { connect } from "react-redux";
import CustomDomain from "./CustomDomain";

const mapStateToProps = (state, props) => {
  const organization = state.organizations[Object.keys(state.organizations)[0]];
  if (!organization) return {};
  const projectId = props.projectId;
  const project = organization.projects[projectId];
  return {
    user: state.auth.user,
    project: project,
    organization: organization,
    projectId,
    ...props,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // setAppState: (newState) => {
    //   dispatch(setAppState(newState));
    // },
  };
};

const CustomDomainPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomDomain);

export default CustomDomainPage;
