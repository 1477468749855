import React, { useEffect, useState } from "react";
import { Form } from "../../../../../components/Misc/Form";
import { EditorPlaceholder, SiteEditorStyle, SitePreview } from "./styled";
import { Button } from "../../../../../components/Misc/Button";
import { routes } from "../../../../../core/constants/routes";
import { parseString } from "../../../../../core/services/parseString";

const initialState = {
  name: "",
  showLinkInMenu: false,
  showLinkInFooter: false,
  showMenu: true,
  showFooter: true,
};

export default function SiteEditor({
  project,
  projectName,
  site,
  updateSite,
  deleteSite,
  selectSite,
}) {
  const [state, setState] = useState(initialState);

  function onChange(event) {
    const target = event.target;

    setState((prevState) => ({
      ...prevState,
      [event.target.name]:
        target.type === "checkbox" ? target.checked : target.value,
    }));
  }

  useEffect(() => {
    setState(site ? site : initialState);
  }, [site]);

  if (!site) {
    return (
      <EditorPlaceholder>
        Klicke auf eine Seite, um sie zu bearbeiten
      </EditorPlaceholder>
    );
  }

  return (
    <SiteEditorStyle>
      <SitePreview>
        <iframe
          src={`https://${project.subdomain}.trixgames.de/${
            site.urlName || parseString(site.name)
          }`}
          // src={routes.projects.userProjectSite(
          //   parseString(projectName),
          //   site.urlName || parseString(site.name)
          // )}
        />
      </SitePreview>

      <hr />

      <Form
        onSubmit={(event) => {
          event.preventDefault();
          updateSite(site.id, state);
          selectSite("");
        }}
        className={"edit-form"}
      >
        <div className={"input-group"}>
          <label htmlFor={"site-name"}>Seiten Titel</label>
          <input
            type={"text"}
            value={state.name}
            onChange={onChange}
            name={"name"}
            id={"site-name"}
          />
        </div>

        <div className={"input-group-checkbox"}>
          <input
            type={"checkbox"}
            name={"showLinkInMenu"}
            checked={state.showLinkInMenu}
            onChange={onChange}
          />
          <label htmlFor={"showLinkInMenu"}>Link im Menü anzeigen</label>
        </div>

        <div className={"input-group-checkbox"}>
          <input
            type={"checkbox"}
            name={"showLinkInFooter"}
            checked={state.showLinkInFooter}
            onChange={onChange}
          />
          <label htmlFor={"showLinkInFooter"}>Link im Footer anzeigen</label>
        </div>

        <div className={"input-group-checkbox"}>
          <input
            type={"checkbox"}
            name={"showMenu"}
            checked={state.showMenu}
            onChange={onChange}
          />
          <label htmlFor={"showMenu"}>Menü anzeigen</label>
        </div>

        <div className={"input-group-checkbox"}>
          <input
            type={"checkbox"}
            name={"showFooter"}
            checked={state.showFooter}
            onChange={onChange}
          />
          <label htmlFor={"showMenu"}>Footer anzeigen</label>
        </div>

        <div className={"button-row"}>
          <Button
            className={"red"}
            type={"button"}
            onClick={() => deleteSite(site.id)}
          >
            Löschen
          </Button>
          <Button className={"primary"} type={"submit"}>
            Speichern
          </Button>
        </div>
      </Form>
    </SiteEditorStyle>
  );
}
