import styled from "styled-components";
import { spacing } from "../../core/constants/spacing";
import { colors } from "../../core/constants/colors";
import { Form } from "../../components/Misc/Form";
import { AppPage } from "../../components/Misc/AppPage";

export const PushStyle = styled(AppPage)`
  .shortening-hint {
    margin: ${spacing.spaceXs} 0 0 0;

    color: ${colors.greys["500"]};
  }

  .jodit-placeholder {
    z-index: unset;
  }

  ${Form} {
    border-style: none solid solid solid;

    .foto-input {
      max-width: 330px;
    }

    .input-group {
      width: 100%;
      max-width: ${spacing.space8xl};
    }

    input {
      max-width: 800px;
      //width: 100%;
    }

    .send-time-time {
      display: none;
    }

    .date-time {
      display: flex;
      flex-direction: row;
    }
  }

  .react-tabs__tab-list {
    margin-bottom: 0;
    border-bottom: 1px solid ${colors.greys["200"]};

    li {
      border-radius: 0;
    }

    .react-tabs__tab--selected {
      //border-style: solid;
      //border-width: 1px;
      border-color: ${colors.greys["200"]};
    }
  }
`;
