import styled from "styled-components";
import { spacing } from "../../core/constants/spacing";
import dimensions from "../../core/constants/dimensions";
import breakpoints from "../../core/constants/breakpoints";

export const AppPage = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: ${spacing.spaceSm} ${spacing.spaceXs};

  @media (max-width: ${breakpoints.large}) {
    padding: ${spacing.spaceXs};
  }

  @media (max-width: ${breakpoints.small}) {
    padding: ${spacing.space2xs};
  }
`;
