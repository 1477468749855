import { Button } from "../../../components/Misc/Button";
import { Form } from "../../../components/Misc/Form";
import React, { useEffect, useRef, useState } from "react";
import { authenticatedRequest } from "../../../core/services/authenticatedRequest";
import { routes } from "../../../core/constants/routes";
import * as Sentry from "@sentry/gatsby";
import { navigate } from "gatsby";

export function ProjectSettings({
  organization,
  project,
  updateProject,
  setAppState,
  projectId,
}) {
  const [projectState, setProjectState] = useState({
    logo: project.logoUrl || "",
    name: project.name || "",
    shortName: project.pwaSettings?.shortName || "",
    backgroundColor: project.pwaSettings?.backgroundColor || "",
    themeColor: project.pwaSettings?.themeColor || "",
  });
  const fileRef = useRef();
  const [fileInputChange, setFileInputChange] = useState(false);

  useEffect(() => {
    try {
      if (fileRef.current.files && fileRef.current.files[0]) {
        const reader = new FileReader();
        reader.onload = (event) => {
          setProjectState((prevState) => ({
            ...prevState,
            logo: event.target.result,
          }));
        };
        reader.readAsDataURL(fileRef.current.files[0]);
      }
    } catch (err) {
      Sentry.captureException(err);
      console.error(err);
    }
  }, [fileInputChange]);

  function onProjectChange(event) {
    setProjectState((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  }

  function onProjectSubmit(event) {
    event.preventDefault();
    setAppState({
      loading: true,
    });

    const { logo, name, ...pwaSettings } = projectState;

    // update project settings
    authenticatedRequest({
      method: "put",
      url: routes.api.organizationProject(organization.id, projectId),
      data: {
        pwaSettings,
      },
    })
      .then((result) => {
        // update local state
        updateProject(organization.id, projectId, result);
      })
      .then(() => {
        // save logo
        if (logo.startsWith("data:image")) {
          authenticatedRequest({
            method: "post",
            url: routes.api.organizationProjectLogo(organization.id, projectId),
            data: {
              logo: projectState.logo,
            },
          })
            .then((result) => {
              updateProject(organization.id, projectId, {
                logoUrl: result.data.url,
              });
              setAppState({
                loading: false,
                error: false,
                statusMessage: "Projekt Einstellungen gespeichert.",
              });
            })
            .catch((err) => {
              setAppState({
                loading: false,
                error: true,
                statusMessage:
                  "Beim Speichern der Daten ist ein Fehler aufgetreten: Keine Datei ausgewählt.",
              });
              console.log(err);
            });
        }
      })
      .catch((err) => {
        setAppState({
          loading: false,
          error: true,
          statusMessage: "Beim Speichern der Daten ist ein Fehler aufgetreten.",
        });
        console.log(err);
      });
  }

  return (
    <Form onSubmit={onProjectSubmit}>
      <div className={"input-group"}>
        <label htmlFor={"name"}>Name</label>
        <input
          id={"name"}
          type={"text"}
          disabled={true}
          value={projectState.name}
          name={"name"}
          onChange={onProjectChange}
        />
      </div>

      <div className={"input-group"}>
        <label htmlFor={"project-logo"}>Logo</label>
        <input
          id="project-logo"
          className={"foto-input"}
          type="file"
          name={"logo"}
          ref={fileRef}
          accept={"image/png, image/jpeg"}
          onChange={() => {
            setFileInputChange(!fileInputChange);
          }}
        />
        {projectState.logo ? (
          <img
            id="image"
            src={projectState.logo}
            alt="profile logo"
            className={"image-preview"}
          />
        ) : (
          ""
        )}
      </div>

      <br />
      <h4>PWA</h4>
      <div className={"input-group"}>
        <label htmlFor={"short-name"}>App Name</label>
        <p> ({"<"}12 Zeichen empfohlen)</p>
        <input
          id={"short-name"}
          type={"text"}
          value={projectState.shortName}
          name={"shortName"}
          onChange={onProjectChange}
        />
      </div>

      <div className={"input-group"}>
        <label htmlFor={"background-color"}>Hintergrundfarbe</label>
        <input
          id={"background-color"}
          type={"color"}
          value={projectState.backgroundColor}
          name={"backgroundColor"}
          onChange={onProjectChange}
        />
      </div>

      <div className={"input-group"}>
        <label htmlFor={"background-color"}>Akzent Farbe</label>
        <input
          id={"theme-color"}
          type={"color"}
          value={projectState.themeColor}
          name={"themeColor"}
          onChange={onProjectChange}
        />
      </div>

      <div className={"button-row"}>
        <Button className={"primary"}>Speichern</Button>
      </div>
    </Form>
  );
}
