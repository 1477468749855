import React from "react";
import { connect } from "react-redux";
import ChatwootSetup from "./ChatwootSetup";
import { updateProject } from "../../../../../core/redux/organizations/action";

const mapStateToProps = (state, props) => {
  const organization = state.organizations[Object.keys(state.organizations)[0]];
  if (!organization) return {};

  const project = organization.projects[props.projectId];

  return {
    user: state.auth.user,
    organization: organization,
    project: project,
    location: props.location,
    ...props,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateProject: (orgId, projectId, patch) =>
      dispatch(updateProject(orgId, projectId, patch)),
  };
};

const ChatwootSetupPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(ChatwootSetup);

export default ChatwootSetupPage;
