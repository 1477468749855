import BuilderControl from "../../../components/Builder/contentbuilder/buildercontrol";
import React, { useEffect, useRef, useState } from "react";
import { navigate } from "gatsby";
import * as Sentry from "@sentry/gatsby";
import { authenticatedRequest } from "../../../core/services/authenticatedRequest";
import { routes } from "../../../core/constants/routes";
import AppLayout from "../../../components/Layout/AppLayout";
import LayoutSettings from "../../../components/Builder/LayoutSettings";

export default function FooterBuilder({
  user,
  organization = {},
  project = {},
  updateProject,
  location,
  projectId,
}) {
  const [loading, setLoading] = useState(true);
  const [html, setHtml] = useState(project.footer || "");
  const [isEnabled, setIsEnabled] = useState(true);
  const [layoutOpen, setLayoutOpen] = useState(false);
  const builderRef = useRef();
  // updates the builder with the user's layout settings
  const [forceUpdate, setForceUpdate] = useState(false);
  const [uiLoading, setUiLoading] = useState({
    save: false,
    saveAndFinish: false,
  });

  useEffect(() => {
    if (loading) return;
    console.log("force update on open");
    setForceUpdate(!forceUpdate);
  }, [loading]);

  useEffect(() => {
    // if menu is not loaded, return to site menu
    // this is the case, if user is logs in on the url of the builder
    if (!Object.keys(organization).length || !Object.keys(project).length) {
      navigate(routes.projectWebsite(projectId));
      return;
    }
    authenticatedRequest({
      method: "get",
      url: routes.api.organizationProject(organization.id, project.id),
    })
      .then((result) => {
        console.log(result);
        setHtml(result.data.footer);
        setLoading(false);
        // console.log(builderRef.current);
        // builderRef.current.state.html = r.menu;
      })
      .catch((err) => console.error(err));
  }, []);

  function onChange() {}

  function onRender() {}

  function onSave(e) {
    // avoid double click / concurrent saving
    if (uiLoading.saveAndFinish || uiLoading.save) return;

    setUiLoading((prevState) => ({ ...prevState, save: true }));

    authenticatedRequest({
      method: "put",
      url: routes.api.organizationProject(organization.id, project.id),
      data: { footer: e },
    })
      .then((result) => {
        updateProject(organization.id, project.id, {
          footer: result.data.footer,
        });
        setUiLoading((prevState) => ({ ...prevState, save: false }));
      })
      .catch((err) => {
        console.log(err);
        Sentry.captureException(err);
      });
  }

  async function onSaveAndFinish(e) {
    // avoid double click / concurrent saving
    if (uiLoading.saveAndFinish || uiLoading.save) return;

    setUiLoading((prevState) => ({ ...prevState, saveAndFinish: true }));

    authenticatedRequest({
      method: "put",
      url: routes.api.organizationProject(organization.id, project.id),
      data: { footer: e },
    })
      .then((result) => {
        setUiLoading((prevState) => ({ ...prevState, saveAndFinish: false }));
        updateProject(organization.id, project.id, {
          footer: result.data.footer,
        });
        navigate(routes.projectWebsite(projectId));
      })
      .catch((err) => {
        console.log(err);
        Sentry.captureException(err);
      });
  }

  function closeBuilder() {
    const answer = window.confirm(
      "Möchtest du den Editor wirklich verlassen? Ungespeicherte Änderungen gehen verloren."
    );
    // cancel the navigation and stay on the same page
    if (!answer) return false;

    // destroy();
    navigate(routes.projectWebsite(project.id));
  }

  return (
    <AppLayout
      showHeader={true}
      title={"Footer"}
      subtitle={project.name}
      lastRoute={routes.project(project.id)}
      pageWidth={user?.preferences?.editorWidth}
      location={location}
      projectId={project.id}
    >
      <BuilderControl
        organization={organization}
        project={project}
        applyLayout={() => {
          setForceUpdate(true);
          setLayoutOpen(true);
          setLayoutOpen(false);
          setForceUpdate(false);
        }}
        ref={builderRef}
        enable={isEnabled}
        initialHtml={html}
        onChange={onChange}
        onRender={onRender}
        onSave={onSave}
        onSaveAndFinish={onSaveAndFinish}
        doSave={(f) => (onSave = f)}
        fileSelect={"/files.html"}
        imageSelect={"/images.html"}
        base64Handler={routes.api.organizationProjectSaveImages(
          organization.id,
          project.id
        )}
        largerImageHandler={routes.api.organizationProjectSaveImages(
          organization.id,
          project.id
        )}
        snippetFile={"/assets/minimalist-blocks/content.js"}
        languageFile={"/contentbuilder/lang/en.js"}
        editorWidth={user?.preferences?.editorWidth}
      />

      <div
        className="is-ui"
        style={{
          position: "fixed",
          right: "30px",
          bottom: "30px",
          display: "flex",
          zIndex: 3,
        }}
      >
        <button
          type="button"
          onClick={() => setLayoutOpen(true)}
          style={{ width: "85px" }}
        >
          Layout
        </button>
        <button type="button" onClick={closeBuilder} style={{ width: "85px" }}>
          Close
        </button>
        <button
          type="button"
          onClick={() => {
            builderRef.current.saveContent();
          }}
          style={{ width: "85px" }}
        >
          {uiLoading.save ? "Saving..." : "Save"}{" "}
        </button>
        <button
          type="button"
          onClick={() => {
            builderRef.current.saveContentAndFinish();
          }}
          style={{ width: "125px" }}
        >
          {uiLoading.saveAndFinish ? "Saving..." : "Save & Finish"}
        </button>
      </div>
      <LayoutSettings
        isOpen={layoutOpen}
        close={() => {
          setLayoutOpen(false);
        }}
        orgId={organization.id}
        project={project}
        mode={"footer"}
        forceUpdate={forceUpdate}
      />
    </AppLayout>
  );
}
