import React from "react";
import styled from "styled-components";
export const FooterIcon = ({
  fill,
  stroke,
  strokeLinecap,
  strokeLineJoin,
  strokeWidth,
  width,
  height,
}) => {
  const style = {
    fill: fill || "black",
    stroke: stroke || "currentColor",
    strokeLinecap: strokeLinecap || "round",
    strokeLineJoin: strokeLineJoin || "round",
    strokeWidth: strokeWidth || "2",
    // width: width || "",
    // if this should be adjusted, need to get / make a better svg
    // I'll need to look into svg's more, made this on the fly
    width: "20px",
    height: height || "",
  };

  const rectStyle = {
    fill: "none",
    stroke: "#009e91",
  };

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      style={{
        width: "20px",
        height: "24px",
      }}
    >
      <g>
        <title>Layer 1</title>
        <rect
          strokeWidth="4"
          id="svg_1"
          height="24"
          width="20"
          y="0"
          x="0"
          stroke="#000"
          fill="#fff"
          style={rectStyle}
        />
        <rect
          strokeWidth="2"
          id="svg_3"
          height="3"
          width="10"
          y="16"
          x="5"
          stroke="#000"
          style={rectStyle}
        />
        {/*<rect*/}
        {/*  stroke-width="19"*/}
        {/*  id="svg_6"*/}
        {/*  height="7"*/}
        {/*  width="20"*/}
        {/*  y="354.5"*/}
        {/*  x="274"*/}
        {/*  stroke="#000"*/}
        {/*  fill="#fff"*/}
        {/*/>*/}
      </g>
    </svg>
  );
};
