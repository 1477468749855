import React, { useEffect, useState } from "react";
import GlobalStyle from "./GlobalStyle";
import styled from "styled-components";
import { spacing } from "../../core/constants/spacing";
import { colors } from "../../core/constants/colors";
import ReduxWrapper from "../../core/redux/ReduxWrapper";
import dimensions from "../../core/constants/dimensions";
import Header from "./Header";
import {
  fetchOrganizations,
  fetchSites,
} from "../../core/redux/organizations/thunk";
import store from "../../core/redux/store";
import Loader from "react-loader-spinner";

const AppPage = styled.div`
  display: flex;
  flex-direction: row;

  width: 100%;

  margin: 0 auto;
  max-width: ${(props) =>
    props.pageWidth ? props.pageWidth : dimensions.desktopWidth};
`;

function AppLayout({
  children,
  path,
  title,
  subtitle,
  lastRoute,
  showNavigation,
  showHeader,
  pageWidth,
  location,
  projectId,

}) {
  const state = store.getState();
  const [loaded, setLoaded] = useState(
    !!Object.keys(state.organizations).length
  );

  useEffect(() => {
    if (!Object.keys(state.organizations).length) {
      store.dispatch(fetchOrganizations());
    } else {
      setLoaded(true);
    }
  }, [state.organizations]);

  if (!loaded) {
    return (
      <Loader
        type="Triangle"
        color="hsla(170, 45%, 35%, 1)"
        height={100}
        width={100}
        timeout={0} //3 secs
      />
    );
  }

  return (
    <ReduxWrapper>
      <GlobalStyle />

      <Header
        title={title}
        subtitle={subtitle}
        lastRoute={lastRoute}
        showNavigation={showNavigation}
        showHeader={showHeader}
        pageWidth={pageWidth}
        projectId={projectId}
      />

      <AppPage pageWidth={pageWidth}>{children}</AppPage>
    </ReduxWrapper>
  );
}

export default AppLayout;
