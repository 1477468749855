import { SET_APP_STATE, SET_FETCHING } from "./action";

const initialState = {
  fetching: false,
  loading: false,
  error: false,
  statusMessage: "",
};

export const appState = (state = initialState, action) => {
  switch (action.type) {
    case SET_APP_STATE: {
      return action.newState;
    }

    case SET_FETCHING: {
      return Object.assign({}, state, {
        fetching: action.fetching,
      });
    }

    default:
      return state;
  }
};
