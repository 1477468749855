import React from "react";
import AppLayout from "../../components/Layout/AppLayout";
import { routes } from "../../core/constants/routes";
import { Link } from "gatsby";
import { SimpleGrid } from "../../components/Misc/SimpleGrid";
import { AppPage } from "../../components/Misc/AppPage";
import { QRCodeIcon } from "../../assets/icons/QRCode";
import { ChatIcon } from "../../assets/icons/ChatIcon";
import { GlobeIcon } from "../../assets/icons/GlobeIcon";
import { CookieIcon } from "../../assets/icons/CookieIcon";

export default function Plugins({ projectId, location, path }) {
  return (
    <AppLayout
      showHeader={true}
      title={"Plugins"}
      showNavigation={true}
      lastRoute={routes.project(projectId)}
      location={location}
      projectId={projectId}
    >
      <AppPage>
        <SimpleGrid>
          <Link
            className={"grid-link"}
            to={routes.projectCodeGenerator(projectId)}
          >
            <QRCodeIcon stroke={"unset"} />
            <span>QR-Code Generator</span>
          </Link>

          <Link
            className={"grid-link"}
            to={routes.projectChatwootDashboard(projectId)}
          >
            <ChatIcon />
            <span>WhatsApp</span>
          </Link>

          <Link
            className={"grid-link"}
            to={routes.projectCustomDomain(projectId)}
          >
            <GlobeIcon />
            <span>Custom Domain</span>
          </Link>

          <Link
            className={"grid-link"}
            to={routes.projectPluginsCookieConsent(projectId)}
          >
            <CookieIcon stroke={"currentColor"} />
            <span>Cookie Banner</span>
          </Link>
        </SimpleGrid>
      </AppPage>
    </AppLayout>
  );
}
