import React from "react";
import { connect } from "react-redux";
import { updateProject } from "../../../../core/redux/organizations/action";
import store from "../../../../core/redux/store";
import { setAppState } from "../../../../core/redux/appState/action";
import CodeGeneratorComponent from "./CodeGenerator";

const mapStateToProps = (state, props) => {
    const organization = state.organizations[Object.keys(state.organizations)[0]];
    if (!organization) return {};

    const project = organization.projects[props.projectId];

    return {
        user: state.auth.user,
        organization: organization,
        project: project,
        location: props.location,
        ...props,
    };
};

const mapDispatchToProps = (dispatch) => {

    return {

    };
};

const CodeGenerator = connect(mapStateToProps, mapDispatchToProps)(CodeGeneratorComponent);

export default CodeGenerator;
