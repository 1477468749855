import React from "react";
import styled from "styled-components";
import { spacing } from "../../core/constants/spacing";
import borderRadius from "../../core/constants/borderRadius";
import { colors } from "../../core/constants/colors";
import fontSize from "../../core/constants/typeScale";

export const Button = styled.button`
  padding: ${spacing.spaceXs};

  background-color: white;
  color: ${colors.primary["170"]};
  border-style: solid;
  border-width: 1px;
  //border-radius: ${borderRadius.small};
  font-size: ${fontSize.textMd};
  letter-spacing: 0.12em;
  cursor: pointer;

  &:disabled {
    background-color: ${colors.greys["500"]} !important;
    cursor: not-allowed;
  }

  &.primary {
    background-color: ${colors.primary["130"]};
    color: white;
    border-style: none;

    box-shadow: ${colors.greys["700"]} 2px 2px 5px;
  }

  // default
  &.secondary {
    background-color: white;
    color: ${colors.primary["170"]};
  }

  &.red {
    background-color: ${colors.red.button};
    color: white;
  }

  &.link-button {
    padding: 0;
    display: flex;

    background-color: unset;
    letter-spacing: unset;
    border-style: none;
    text-decoration: underline;
    color: ${colors.primary["170"]};
  }
`;
