import React from 'react';

export const ListIcon = ({
  fill,
  stroke,
  strokeLinecap,
  strokeLineJoin,
  strokeWidth,
}) => {
  const style = {
    fill: fill || 'none',
    stroke: stroke || 'white',
    strokeLinecap: strokeLinecap || 'round',
    strokeLineJoin: strokeLineJoin || 'round',
    strokeWidth: strokeWidth || '32px',
  };

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="512"
      height="512"
      viewBox="0 0 512 512"
    >
      <line x1="160" y1="144" x2="448" y2="144" style={style} />
      <line x1="160" y1="256" x2="448" y2="256" style={style} />
      <line x1="160" y1="368" x2="448" y2="368" style={style} />
      <circle cx="80" cy="144" r="16" style={style} />
      <circle cx="80" cy="256" r="16" style={style} />
      <circle cx="80" cy="368" r="16" style={style} />
    </svg>
  );
};
