import React, { useEffect, useRef, useState } from "react";
import { Form } from "../../../components/Misc/Form";
import CreatableSelect from "react-select/creatable/dist/react-select.esm";
import { JoEditor } from "../../../components/Misc/JoEditor";
import { Button } from "../../../components/Misc/Button";
import * as Sentry from "@sentry/gatsby";

export function PushNotificationForm({
  state,
  setState,
  onSubmit,
  channelOptions,
}) {
  const [fileInputChange, setFileInputChange] = useState(false);
  const fileRef = useRef();

  useEffect(() => {
    if (state.sendNow) {
      const now = new Date(Date.now());
      setState((prevState) => ({
        ...prevState,
        sendTime: now.toLocaleTimeString(),
        sendDate: now.toISOString().substr(0, 10),
      }));
    }
  }, [state.sendNow]);

  useEffect(() => {
    try {
      if (fileRef.current.files && fileRef.current.files[0]) {
        const reader = new FileReader();
        reader.onload = (event) => {
          setState((prevState) => ({
            ...prevState,
            image: event.target.result,
          }));
        };
        reader.readAsDataURL(fileRef.current.files[0]);
      }
    } catch (err) {
      Sentry.captureException(err);
      console.error(err);
    }
  }, [fileInputChange]);

  function onChange(event) {
    if (event.target.type === "checkbox") {
      setState((prevState) => ({
        ...prevState,
        [event.target.name]: event.target.checked,
      }));
      return;
    }
    setState((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  }

  function onSelectChange(newState) {
    setState((prevState) => ({
      ...prevState,
      channel: newState,
    }));
  }

  return (
    <Form onSubmit={onSubmit}>
      <div className={"input-group"}>
        <label htmlFor={"push-photo"}>Foto</label>
        <input
          id="push-photo"
          className={"foto-input"}
          type="file"
          name={"imageSrc"}
          ref={fileRef}
          accept={"image/png, image/jpeg"}
          onChange={() => {
            setFileInputChange(!fileInputChange);
          }}
        />
        {state.image ? (
          <img
            id="image"
            src={state.image}
            alt="profile picture"
            className={"image-preview"}
          />
        ) : (
          ""
        )}
      </div>

      <div className={"input-group"}>
        <label htmlFor={"push-title"}>Titel</label>
        <input
          id={"push-title"}
          type={"text"}
          name={"title"}
          onChange={(event) => onChange(event)}
          value={state.title}
          required={true}
        />
      </div>

      <div className={"input-group"}>
        <label htmlFor={"push-channel"}>Kanal</label>
        <p className={"channel-hint"}>
          tippen, um einen neuen Kanal zu erstellen
        </p>
        <CreatableSelect
          isMulti
          id={"push-channel"}
          name={"channel"}
          onChange={onSelectChange}
          options={channelOptions}
        />
      </div>

      <div className={"input-group"}>
        <label htmlFor={"push-description"}>Beschreibung</label>
        <JoEditor
          val={state.descriptionHtml}
          handler={(newContent) => {
            setState((prevState) => ({
              ...prevState,
              descriptionHtml: newContent,
            }));
          }}
        />

        <p className={"shortening-hint"}>
          Die Beschreibung erscheint in voller Länger im Newsfeed, wird für die
          Pushbenachrichtigung aber gekürzt. Bei Benachrichtungen mit Bildern
          wird meist 1 Zeile angezeigt, anonsten 2-3, je nach Gerät.
        </p>
      </div>

      <div className={"input-group-checkbox"}>
        <input
          type={"checkbox"}
          id={"sendNow"}
          name={"sendNow"}
          checked={state.sendNow}
          onChange={onChange}
        />
        <label htmlFor={"sendNow"}>Jetzt senden</label>
      </div>

      <div
        className={"input-group"}
        style={{
          display: state.sendNow ? "none" : "flex",
        }}
      >
        <label htmlFor={"sendTimeDate"}>Planen für</label>
        <div className={"date-time"}>
          <input
            id={"sendTimeDate"}
            type={"date"}
            name={"sendDate"}
            value={state.sendDate}
            onChange={onChange}
            disabled={state.sendNow}
          />
          <label htmlFor={"sendTimeTime"} className={"send-time-time"}>
            Uhrzeit
          </label>
          <input
            id={"sendTimeTime"}
            type={"time"}
            name={"sendTime"}
            value={state.sendTime}
            onChange={onChange}
            disabled={state.sendNow}
          />
        </div>
      </div>

      <div className={"input-group-checkbox"}>
        <input
          type={"checkbox"}
          id={"allowReplies"}
          name={"allowReplies"}
          checked={state.allowReplies}
          onChange={onChange}
        />
        <label htmlFor={"allowReplies"}>Antworten erlauben</label>
      </div>

      <div className={"button-row"}>
        <Button className={"primary"} type={"submit"}>
          Senden
        </Button>
      </div>

      <br />
    </Form>
  );
}
