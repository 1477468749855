import React, { useEffect } from "react";
import { routes } from "../../../../../core/constants/routes";
import { navigate } from "gatsby";
import { AppPage } from "../../../../../components/Misc/AppPage";
import AppLayout from "../../../../../components/Layout/AppLayout";
import WhatsappComponent from "./Whatsapp";
import { authenticatedRequest } from "../../../../../core/services/authenticatedRequest";

export default function ChatwootDashboard({
  user,
  project,
  location,
  patchUser,
  fetchOrganizations,
}) {
  useEffect(() => {
    authenticatedRequest({ method: "get", url: routes.api.userProfile }).then(
      (r) => {
        patchUser(r.data);
      }
    );
    fetchOrganizations();
  }, []);

  // forward user to setup
  if (!project.whatsapp.jid) {
    navigate(routes.projectChatwootSetup(project.id));
    return "";
  }

  return (
    <AppLayout
      showHeader={true}
      title={"Chatwoot Dashboard"}
      subtitle={project?.name}
      showNavigation={true}
      location={location}
      projectId={project?.id}
      lastRoute={routes.projectPlugins(project.id)}
    >
      <AppPage>
        <a href={routes.chatwood.base} target={"_blank"}>
          Link zu Chatwoot
        </a>
        <h4>Login Daten:</h4>
        <p>{user.email}</p>
        <p>Passwort: wie bei Postspot</p>
        <br />

        <p>
          Dein Account ist verbunden. Du kannst die Verbindung trennen, indem du
          in Whatsapp auf "Verknüpfte Geräte" gehst, und dann "Chrome(Baileys)"
          entfernst.
        </p>
      </AppPage>
    </AppLayout>
  );
}
