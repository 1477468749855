import React from "react";
import { connect } from "react-redux";
import { updateProject } from "../../../core/redux/organizations/action";
import LayoutSettingsComponent from "./LayoutSettingsComponent";

const mapStateToProps = (state, props) => {
  return {
    orgId: props.orgId,
    project: props.project,
    isOpen: props.isOpen,
    close: props.close,
    mode: props.mode,
    forceUpdate: props.forceUpdate,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateProject: (orgId, projectId, patch) =>
      dispatch(updateProject(orgId, projectId, patch)),
  };
};

const LayoutSettings = connect(
  mapStateToProps,
  mapDispatchToProps
)(LayoutSettingsComponent);

export default LayoutSettings;
