import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

export default function CookiePreview({ settings }) {
  function settingsToOsantoOptions(settings) {
    const options = {};

    // position
    options.position = settings.position;
    if (settings.position === "top-static") options.static = true;

    // layout
    options.theme = settings.theme;
    if (settings.layout === "wire") {
      options.button = {
        background: "transparent",
        text: "#f1d600",
        border: "#f1d600",
      };
    }

    // compliance type
    options.type = settings.type;

    // colors
    options.palette = {
      popup: {
        background: settings.colors.banner,
        text: settings.colors.bannerText,
      },
      button: {
        ...options.button,
        background: settings.colors.button,
        text: settings.colors.buttonText,
      },
    };

    // texts & policy link
    options.content = settings.content;

    if (settings.type === "opt-in") {
      delete options.deny;
    } else if (settings.type === "opt-out") {
      delete options.allow;
    } else {
      delete options.content.allow;
      delete options.content.deny;
    }

    console.log(options);
    return options;
  }

  useEffect(() => {
    console.log(settings, window.cookieconsent);
    console.log(document.querySelector(".cc-window"));
    document.querySelector(".cc-window")?.remove();
    if (window.cookieconsent) {
      console.log(window.cookieconsent);

      // window.cookieconsent.status = {
      //   allow: "allow",
      //   deny: "deny",
      //   dismiss: "dismiss",
      // };
    }

    window.cookieconsent?.initialise(settingsToOsantoOptions(settings));
  }, [settings]);
  return (
    <>
      <button
        onClick={() => {
          console.log(window.cookieconsent);
        }}
      >
        button
      </button>
    </>
  );
}
