import { refreshAndCall } from "./auth";
import axios from "axios";
import store from "../redux/store";

export function authenticatedRequest({ method = "get", url, data }) {
  return refreshAndCall(() => {
    const jwt = store.getState().auth.user.token.accessToken;

    return axios({
      method: method,
      url: url,
      data: data,
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    });
  });
}
