import React, { useEffect, useRef, useState } from "react";
import AppLayout from "../../../components/Layout/AppLayout";
import { authenticatedRequest } from "../../../core/services/authenticatedRequest";
import { routes } from "../../../core/constants/routes";
import * as Sentry from "@sentry/gatsby";
import { AppPage } from "../../../components/Misc/AppPage";
import { objectNotEmpty } from "../../../core/services/helpers";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { StyledIframe, StyledIframeWrapper } from "./styled";

export default function BuildComponent({
  location,
  organization,
  project = {},
  updateProject,
  projectId,
  setAppState,
}) {
  let updateFunc;
  const [logs, setLogs] = useState(project.buildLog);
  const textareaRef = useRef();
  const [status, setStatus] = useState();
  const [statusMessage, setStatusMessage] = useState("");

  useEffect(() => {
    // refresh local state on page load / page refresh
    if (objectNotEmpty(project) && objectNotEmpty(organization)) {
      getProject();
    }
  }, [!!organization, !!project]);

  useEffect(() => {
    setStatus(project.buildStatus);
    switch (project.buildStatus) {
      case 0: {
        setStatusMessage("in progress");
        updateFunc = window.setInterval(getProject, 5000);
        setAppState({
          loading: true,
          error: false,
          statusMessage: "",
        });
        break;
      }
      case -1: {
        setStatusMessage("error");
        clearInterval(updateFunc);
        setAppState({
          loading: false,
          error: true,
          statusMessage: "Ein Fehler ist aufgetreten",
        });
        break;
      }
      case 1: {
        setStatusMessage("successful");
        clearInterval(updateFunc);
        setAppState({
          loading: false,
          error: false,
          statusMessage: "Build erfolgreich",
        });
        break;
      }
    }

    // stop fetching after component unmount
    return () => {
      try {
        window.clearInterval(updateFunc);
      } catch (err) {
        console.error(err);
      }
    };
  }, [project.buildStatus]);

  function triggerBuild() {
    authenticatedRequest({
      method: "post",
      url: routes.api.organizationProjectBuild(organization.id, projectId),
    })
      .then((r) => {
        // clear Log
        setLogs("");
        setStatus(0);
        // fetch first time after 1s
        setTimeout(getProject, 1000);

        // fetch again after 4s
        setTimeout(getProject, 4000);
      })
      .catch((err) => {
        console.log(err);
        Sentry.captureException(err);
      });
  }

  function getProject() {
    authenticatedRequest({
      method: "get",
      url: routes.api.organizationProject(organization.id, project.id),
    })
      .then((r) => {
        setLogs(r.data.buildLog);
        updateProject(organization.id, project.id, r.data);
      })
      .then(() => {
        textareaRef.current.scrollTop = textareaRef.current.scrollHeight;
      })
      .catch((err) => {
        Sentry.captureException(err);
        console.error(err);
      });
  }

  function onSetup() {
    authenticatedRequest({
      method: "post",
      url: routes.api.organizationProjectSetup(organization.id, project.id),
    })
      .then((r) => {
        console.log(r);
      })
      .catch((err) => {
        Sentry.captureException(err);
        console.error(err);
      });
  }

  function deployPreview() {
    authenticatedRequest({
      method: "post",
      url: routes.api.organizationProjectDeployPreview(
        organization.id,
        project.id
      ),
    })
      .then((r) => {
        console.log(r);
      })
      .catch((err) => {
        Sentry.captureException(err);
        console.error(err);
      });
  }

  const url = "https://" + project.subdomain + ".trixgames.de";
  const previewUrl = "https://" + project.subdomain + "-preview.trixgames.de";

  if (!Object.keys(project).length)
    return (
      <AppLayout
        showHeader={true}
        title={"Build"}
        subtitle={project?.name}
        showNavigation={true}
        location={location}
        projectId={project?.id}
      />
    );
  else {
    return (
      <AppLayout
        showHeader={true}
        title={"Build"}
        subtitle={project.name}
        showNavigation={true}
        lastRoute={routes.projectWebsite(project.id)}
        location={location}
        projectId={project.id}
      >
        <AppPage>
          <Grid container spacing={2} rowSpacing={3}>
            <Grid item xs={12}>
              <Typography variant="h3" component="div" gutterBottom>
                Projekt
              </Typography>
            </Grid>

            {/*production deployment section*/}
            <Grid container item xs={12} columnSpacing={2}>
              <Grid item xs={12}>
                <Typography variant="h5" component="div" gutterBottom>
                  Production Deployment
                </Typography>
                <p>
                  Diese Version ist auf der konfigurierten URL für deine
                  Besucher sichtbar.
                </p>
              </Grid>
              <Grid item xs={6}>
                <StyledIframeWrapper>
                  <iframe src={url} />
                </StyledIframeWrapper>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="h6" component="div" gutterBottom>
                  Domains
                </Typography>
                <a href={url} target={"_blank"} rel={"noreferrer"}>
                  {url}
                </a>
                <Typography variant="h6" component="div" gutterBottom>
                  Status
                </Typography>
                <p>online</p>
              </Grid>
            </Grid>

            {/*preview deployment section*/}
            <Grid container item xs={12} columnSpacing={2}>
              <Grid item xs={12}>
                <Typography variant="h5" component="div" gutterBottom>
                  Preview Deployment
                </Typography>
                <p>Diese Version ist gerade in Bearbeitung.</p>
              </Grid>
              <Grid item xs={6}>
                <StyledIframeWrapper>
                  <iframe src={previewUrl} />
                </StyledIframeWrapper>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="h6" component="div" gutterBottom>
                  Domains
                </Typography>
                <a href={previewUrl} target={"_blank"} rel={"noreferrer"}>
                  {previewUrl}
                </a>

                <Typography variant="h6" component="div" gutterBottom>
                  Status
                </Typography>
                <p>online</p>

                <Button variant="contained" onClick={triggerBuild}>
                  Neuen Build auslösen
                </Button>
                <Button variant="outlined" onClick={deployPreview}>
                  Als Production Build verwenden
                </Button>
              </Grid>
            </Grid>

            <Grid item xs={12} columnSpacing={2}>
              <Typography variant="h6" component="div" gutterBottom>
                Debug log
              </Typography>

              <textarea
                ref={textareaRef}
                rows={20}
                value={logs}
                disabled={true}
                style={{ width: "100%" }}
              />
            </Grid>
          </Grid>

          {/*</Grid>*/}
          {/*<h2>Projekt erstellen/aktualisieren</h2>*/}
          {/*<Button onClick={triggerBuild} className={"primary"}>*/}
          {/*  Build*/}
          {/*</Button>*/}
          {/*/!*<Button onClick={onSetup} className={"secondary"}>*!/*/}
          {/*/!*  Setup*!/*/}
          {/*/!*</Button>*!/*/}
          {/*<br />*/}
          {/*<h4>Status</h4>*/}

          {/*<p*/}
          {/*  style={{*/}
          {/*    fontWeight: "bold",*/}
          {/*    color: status === 0 ? "orange" : status === -1 ? "red" : "green",*/}
          {/*  }}*/}
          {/*>*/}
          {/*  {statusMessage}*/}
          {/*</p>*/}
          {/*<br />*/}
          {/*<h4>Logs</h4>*/}
          {/*<textarea*/}
          {/*  ref={textareaRef}*/}
          {/*  rows={20}*/}
          {/*  value={logs}*/}
          {/*  disabled={true}*/}
          {/*  style={{ width: "100%" }}*/}
          {/*/>*/}
          {/*<label>Deine Projekt URL: </label>*/}
          {/*<a*/}
          {/*  href={`https://${project.name.toLowerCase()}.${*/}
          {/*    process.env.PROJECT_DOMAIN*/}
          {/*  }`}*/}
          {/*  target={"_blank"}*/}
          {/*>*/}
          {/*  https://{project.name.toLowerCase()}.{process.env.PROJECT_DOMAIN}*/}
          {/*</a>*/}
        </AppPage>
      </AppLayout>
    );
  }
}
