import React from "react";
import { Button } from "../../../../../components/Misc/Button";
import { routes } from "../../../../../core/constants/routes";
import styled from "styled-components";
import { navigate } from "gatsby";
import { Form } from "../../../../../components/Misc/Form";

const StepTwoStyle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .actions {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
`;

export function StepOne({ setCurrentStep }) {
  function onSubmit(event) {
    event.preventDefault();

    setCurrentStep(2);
  }

  // setCurrentStep(3);

  return (
    <StepTwoStyle>
      <Form onSubmit={onSubmit}>
        <div className={"input-group"}>
          <label>Domain</label>
          <input type={"text"}></input>
        </div>
        <div className={"button-row"}>
          <Button className={"primary"}>Weiter</Button>
        </div>
      </Form>
    </StepTwoStyle>
  );
}
