const breakpoints = {
  small: "420px",
  medium: "720px",
  large: "1024px",
};

export default breakpoints;

// const breakpoints = {
//   xxSmall: 320,
//   extraSmall: 414,    // mobile phone
//   small: 600,         // small phone landscape
//   smallmedium: 750,
//   medium: 1000,        // big phone landscape / tablets
//   large: 1280,        // tablets
//   extraLarge: 1920,   // desktops
// };
