import axios from "axios";
import { routes } from "../constants/routes";
import store from "../redux/store";
import { setUser } from "../redux/auth/action";
import { reset } from "../redux/reducer";
import * as Sentry from "@sentry/gatsby";
import { fetchOrganizations } from "../redux/organizations/thunk";
import { setFetching } from "../redux/appState/action";

export const isBrowser = () => typeof window !== "undefined";

export const handleLogin = ({ email, password, handleAuthError }) => {
  return new Promise((resolve, reject) => {
    axios
      .post(routes.api.login, {
        email,
        password,
      })
      .then((result) => {
        store.dispatch(setFetching(false));
        store.dispatch(
          setUser(
            Object.assign({}, result.data.user, { token: result.data.token })
          )
        );
      })
      .then((r) => {
        store.dispatch(fetchOrganizations());
        resolve(true);
      })
      .catch((err) => {
        console.error(err);
        handleAuthError(err);
        Sentry.captureException(err);
        reject(false);
      });
  });
};

export function handleLogout() {
  store.dispatch(reset());
  store.dispatch(setUser());
}

export function isTokenExpired() {
  // get token
  const token = store.getState().auth.user.token.accessToken;

  // extract token
  const tokenParts = token.split(".");
  const header = JSON.parse(atob(tokenParts[0]));
  const body = JSON.parse(atob(tokenParts[1]));
  // typ should be "JWT", alg should be "HS256
  const { typ, alg } = header;
  // expiry, issuad at, sub = user id
  const { exp, iat, sub } = body;

  // exp is in seconds, timestamp is in ms
  console.log("token expired?: ", 1000 * exp - Date.now() < 0);
  return 1000 * exp - Date.now() < 0;
}

export function refreshToken() {
  const user = store.getState().auth.user;

  return new Promise((resolve, reject) => {
    axios
      .post(routes.api.refreshToken, {
        email: user.email,
        refreshToken: user.token.refreshToken,
      })
      .then((result) => {
        store.dispatch(
          setUser(Object.assign({}, user, { token: result.data }))
        );
        resolve(true);
      })
      .catch((err) => {
        console.error(err);
        Sentry.captureException(err);
        reject(false);
      });
  });
}

// makes an API request and refreshes the jwt token if neccessary
/**
 *
 * @param callback the function to call after refreshing the token
 */
export function refreshAndCall(callback) {
  return isTokenExpired()
    ? refreshToken()
        .then(() => {
          console.log("token refreshed");
          return callback();
        })
        .catch((err) => {
          console.log("error when trying to refresh token");
          Sentry.captureException(err);
          throw err;
          // happens often if two requests are made at the same time
          // TODO need to avoid, so that I can log the user out here
        })
    : callback();
}
