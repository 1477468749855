import React from "react";
import styled from "styled-components";
import { Button } from "../../../../../components/Misc/Button";
import { navigate } from "gatsby";
import { routes } from "../../../../../core/constants/routes";

const StepTwoStyle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .actions {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
`;

export function StepTwo({ projectId }) {
  return (
    <StepTwoStyle>
      <p>Trage nun bei deinem Domain Registrar folgenden DNS-Record ein:</p>
      <p>Typ: A-Record</p>
      <p>Wert: 157.90.172.201</p>
      <p>Die Umstellung kann einige Zeit dauern.</p>

      <Button
        className={"primary"}
        onClick={() => {
          navigate(routes.projectPlugins(projectId));
        }}
      >
        {" "}
        Fertig
      </Button>
    </StepTwoStyle>
  );
}
