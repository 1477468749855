import React, { useState } from "react";
import { routes } from "../../core/constants/routes";
import { Form } from "../../components/Misc/Form";
import { authenticatedRequest } from "../../core/services/authenticatedRequest";
import AppLayout from "../../components/Layout/AppLayout";
import { AppPage } from "../../components/Misc/AppPage";
import * as Sentry from "@sentry/gatsby";
import { objectEmpty } from "../../core/services/helpers";
import { PushStyle } from "./styled";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { PushNotificationForm } from "./PushNotificationForm";
import { WelcomePushForm } from "./WelcomePushForm";

export default function Push({
  location,
  organization,
  project,
  projectId,
  setAppState,
  fetchOrganizations,
}) {
  const initialPushNotificationState = {
    title: "",
    description: "",
    descriptionHtml: "",
    image: "",
    channel: [],
    sendNow: true,
    sendDate: new Date(Date.now()).toLocaleTimeString(),
    sendTime: new Date(Date.now()).toISOString().substr(0, 10),
    allowReplies: false,
  };
  const [pushNotificationState, setPushNotificationState] = useState(
    initialPushNotificationState
  );

  const initialWelcomePushState = {
    title: "",
    description: "",
    descriptionHtml: "",
    image: "",
  };
  const [welcomePushState, setWelcomePushState] = useState(
    initialWelcomePushState
  );

  const [loading, setLoading] = useState(false);
  const channelOptions = project.channels.map((channel) => {
    return { value: channel, label: channel };
  });

  function onPushSubmit(event) {
    event.preventDefault();

    // if (
    //   !pushNotificationState.descriptionHtml ||
    //   !pushNotificationState.title
    // ) {
    //   setAppState({
    //     loading: false,
    //     statusMessage: "Bitte gib einen Titel und eine Beschreibung ein.",
    //     error: true,
    //   });
    //   return;
    // }

    setAppState({
      loading: true,
      statusMessage: "",
      error: false,
    });

    const description = pushNotificationState.descriptionHtml
      .replace(/<\/.*?>/g, " ")
      .replace(/<(.|\\n)*?>/g, "");

    const channel = pushNotificationState.channel.map((elem) => elem.value);

    authenticatedRequest({
      method: "post",
      url: routes.api.organizationProjectPush(organization.id, project.id),
      data: {
        ...pushNotificationState,
        channel,
        description,
      },
    })
      .then((r) => {
        setAppState({
          loading: false,
          statusMessage: "Push Benachrichtigung erfolgreich gesendet.",
          error: false,
        });
        setPushNotificationState(initialPushNotificationState);
      })
      .then(() => {
        // update project, in case a new channel was added
        fetchOrganizations();
      })
      .catch((error) => {
        setAppState({
          loading: false,
          statusMessage: `Beim Versand der Push Benachrichtigung ist ein Fehler aufgetreten: ${
            error.response?.data?.message || error.response?.data?.code || ""
          }`,
          error: true,
        });
        console.error(error);
        Sentry.captureException(error);
        Sentry.captureException(error.response);
      });
  }

  function onWelcomePushSubmit(event) {
    event.preventDefault();

    // show loading bar
    setAppState({
      loading: true,
      statusMessage: "",
      error: false,
    });

    // html to text
    const description = pushNotificationState.descriptionHtml
      .replace(/<\/.*?>/g, " ")
      .replace(/<(.|\\n)*?>/g, "");

    authenticatedRequest({
      method: "put",
      url: routes.api.organizationProject(organization.id, project.id),
      data: {
        welcomePush: {
          ...welcomePushState,
          description: description,
        },
      },
    })
      .then((r) => {
        setAppState({
          loading: false,
          statusMessage: "Willkommensbenachrichtigung erfolgreich gespeichert.",
          error: false,
        });
        setWelcomePushState(initialWelcomePushState);
        console.log(r);
      })
      .catch((error) => {
        setAppState({
          loading: false,
          statusMessage: `Beim Speichern derWillkommensbenachrichtigung ist ein Fehler aufgetreten: ${
            error.response?.data?.message || error.response?.data?.code || ""
          }`,
          error: true,
        });
        console.error(error);
        Sentry.captureException(error);
        Sentry.captureException(error.response);
      });
  }

  if (objectEmpty(project)) {
    return (
      <AppLayout
        title={"Posten"}
        subtitle={project?.name}
        showHeader={true}
        showNavigation={true}
        lastRoute={routes.project(projectId)}
        location={location}
        projectId={projectId}
      >
        <AppPage>
          <Form />
        </AppPage>
      </AppLayout>
    );
  } else {
    return (
      <AppLayout
        title={"Posten"}
        subtitle={project.name}
        showHeader={true}
        showNavigation={true}
        lastRoute={routes.project(project.id)}
        location={location}
        projectId={project.id}
      >
        <PushStyle>
          <Tabs>
            <TabList>
              <Tab>🔔 Push Benachrichtigung</Tab>
              <Tab>👋 Willkommens PB</Tab>
              <Tab disabled={true}>🗒 Post</Tab>
            </TabList>

            <TabPanel>
              <PushNotificationForm
                onSubmit={onPushSubmit}
                state={pushNotificationState}
                setState={setPushNotificationState}
                channelOptions={channelOptions}
              />
            </TabPanel>
            <TabPanel>
              <WelcomePushForm
                onSubmit={onWelcomePushSubmit}
                state={welcomePushState}
                setState={setWelcomePushState}
              />
            </TabPanel>

            <TabPanel />
          </Tabs>
        </PushStyle>
      </AppLayout>
    );
  }
}
