import {
  ADD_ORGANIZATION,
  ADD_PROJECT_SITE,
  REMOVE_PROJET_SITE,
  SET_ORGANIZATIONS,
  UPDATE_PROJECT,
  UPDATE_PROJECT_POST,
  UPDATE_PROJECT_SITE,
} from "./action";
import * as Sentry from "@sentry/gatsby";

const initialState = [];

export const organizations = (state = initialState, action) => {
  try {
    switch (action.type) {
      case SET_ORGANIZATIONS: {
        // merge objects, as some data like sites is only fetched if required
        let newState = {
          ...state,
          ...action.organizations,
        };

        Object.keys(state).forEach((orgKey) => {
          const stateOrg = state[orgKey];
          const newOrg = action.organizations[orgKey];
          let organization = {
            ...stateOrg,
            ...newOrg,
            users: {
              ...stateOrg.users,
              ...newOrg.users,
            },
          };

          Object.keys(stateOrg.projects).forEach((projectKey) => {
            const stateProject = stateOrg.projects[projectKey];
            const newProject =
              action.organizations[orgKey].projects[projectKey];
            const project = {
              ...stateProject,
              ...newProject,
              sites: {
                ...stateProject.sites,
                ...newProject.sites,
              },
            };
            organization.projects[projectKey] = project;
          });

          newState[orgKey] = organization;
        });

        return newState;
      }

      case ADD_ORGANIZATION: {
        return Object.assign({}, state, {
          organizations: state.organizations
            ? state.organizations.concat(action.organization)
            : [action.organization],
        });
      }

      case UPDATE_PROJECT: {
        return Object.assign(
          {},
          {
            ...state,
            [action.orgId]: {
              ...state[action.orgId],
              projects: {
                ...state[action.orgId].projects,
                [action.projectId]: {
                  ...state[action.orgId].projects[action.projectId],
                  ...action.patch,
                },
              },
            },
          }
        );
      }

      case ADD_PROJECT_SITE: {
        return Object.assign(
          {},
          {
            ...state,
            [action.orgId]: {
              ...state[action.orgId],
              projects: {
                ...state[action.orgId].projects,
                [action.projectId]: {
                  ...state[action.orgId].projects[action.projectId],
                  sites: {
                    ...state[action.orgId].projects[action.projectId].sites,
                    [action.site.id]: action.site,
                  },
                },
              },
            },
          }
        );
      }

      case UPDATE_PROJECT_SITE: {
        return Object.assign(
          {},
          {
            ...state,
            [action.orgId]: {
              ...state[action.orgId],
              projects: {
                ...state[action.orgId].projects,
                [action.projectId]: {
                  ...state[action.orgId].projects[action.projectId],
                  sites: {
                    ...state[action.orgId].projects[action.projectId].sites,
                    [action.siteId]: {
                      ...state[action.orgId].projects[action.projectId].sites[
                        action.siteId
                      ],
                      ...action.patch,
                    },
                  },
                },
              },
            },
          }
        );
      }

      case REMOVE_PROJET_SITE: {
        const newSites = Object.assign(
          {},
          state[action.orgId].projects[action.projectId].sites
        );
        delete newSites[action.siteId];

        return Object.assign(
          {},
          {
            ...state,
            [action.orgId]: {
              ...state[action.orgId],
              projects: {
                ...state[action.orgId].projects,
                [action.projectId]: {
                  ...state[action.orgId].projects[action.projectId],
                  sites: newSites,
                },
              },
            },
          }
        );
      }

      case UPDATE_PROJECT_POST: {
        console.log(action);
        return Object.assign(
          {},
          {
            ...state,
            [action.orgId]: {
              ...state[action.orgId],
              projects: {
                ...state[action.orgId].projects,
                [action.projectId]: {
                  ...state[action.orgId].projects[action.projectId],
                  posts: {
                    ...state[action.orgId].projects[action.projectId].posts,
                    [action.postId]: {
                      ...state[action.orgId].projects[action.projectId].posts[
                        action.postId
                      ],
                      ...action.patch,
                    },
                  },
                },
              },
            },
          }
        );
      }

      default:
        return state;
    }
  } catch (err) {
    console.error(err);
    Sentry.captureException(err);
  }
};
