function componentToHex(c) {
  var hex = c.toString(16);
  return hex.length == 1 ? "0" + hex : hex;
}

export function rgbToHex(r, g, b) {
  return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
}

export function rgbStringToRgbValues(str) {
  console.log("str", str);
  const matchColors = /rgb\((\d{1,3}), (\d{1,3}), (\d{1,3})\)/;
  const match = matchColors.exec(str);
  console.log(match);
  if (!match) return;
  return {
    r: match[1],
    g: match[2],
    b: match[3],
  };
}

// parse color formats to hex
export function parseColor(str) {
  if (str.startsWith("#")) {
    return str;
  } else if (str.startsWith("rgb")) {
    const rgbValues = rgbStringToRgbValues(str);
    const hex = rgbToHex(rgbValues.r, rgbValues.g, rgbValues.b);
    return hex;
  }
}
