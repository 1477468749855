/*
 * ACTION TYPES
 */

export const SET_USER = "SET_USER";
export const PATCH_USER = "PATCH_USER";

/*
 * ACTION CREATORS
 */

export function setUser(user) {
  return {
    type: SET_USER,
    user: user,
  };
}

export function patchUser(patch) {
  return {
    type: PATCH_USER,
    patch: patch,
  };
}
