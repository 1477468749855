import React, { useEffect, useRef, useState } from "react";
import AppLayout from "../../../components/Layout/AppLayout";
import { Form } from "../../../components/Misc/Form";
import { AppPage } from "../../../components/Misc/AppPage";
import { routes } from "../../../core/constants/routes";
import { objectEmpty } from "../../../core/services/helpers";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { OrgSettings } from "./OrgSettings";
import { ProjectSettings } from "./ProjectSettings";
import { AccountSettings } from "./AccountSettings";
import { SettingsStyle } from "../styled";

export default function Settings({
  project,
  user,
  logout,
  organization,
  location,
  projectId,
  updateProject,
  setAppState,
  setUser,
}) {
  useEffect(() => {
    return () => {
      setAppState({
        loading: false,
        error: false,
        statusMessage: "",
      });
    };
  }, []);

  if (objectEmpty(project)) {
    return (
      <AppLayout
        showHeader={true}
        title={"Einstellungen"}
        showNavigation={true}
        lastRoute={routes.project(projectId)}
        location={location}
        projectId={projectId}
      >
        <AppPage>
          <Form />
        </AppPage>
      </AppLayout>
    );
  } else {
    return (
      <AppLayout
        showHeader={true}
        title={"Einstellungen"}
        showNavigation={true}
        lastRoute={routes.project(project.id)}
        location={location}
        projectId={project.id}
      >
        <SettingsStyle>
          <Tabs>
            <TabList>
              <Tab>Organisation</Tab>
              <Tab>Projekt</Tab>
              <Tab>Benutzer</Tab>
            </TabList>

            {/*ORG SETTINGS*/}
            <TabPanel>
              <OrgSettings organization={organization} />
            </TabPanel>

            {/*PROJECT SETTINGS*/}
            <TabPanel>
              <ProjectSettings
                updateProject={updateProject}
                setAppState={setAppState}
                projectId={projectId}
                project={project}
                organization={organization}
              />
            </TabPanel>

            {/*ACCOUNT SETTINGS*/}
            <TabPanel>
              <AccountSettings
                user={user}
                logout={logout}
                setUser={setUser}
                setAppState={setAppState}
              />
            </TabPanel>
          </Tabs>
        </SettingsStyle>
      </AppLayout>
    );
  }
}
