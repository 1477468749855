import styled from "styled-components";
import React from "react";
import downloadFromAppStore from "../../../../../../assets/Chatwoot/downloadOnAppstore.svg";
import { Button } from "../../../../../../components/Misc/Button";
import { routes } from "../../../../../../core/constants/routes";
import { navigate } from "gatsby";

const StepThreeStyle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export function StepTwo({ orgId, orgName, projectId, setCurrentStep }) {
  return (
    <StepThreeStyle>
      <p>Dein Whatsapp Account wurde nun mit {orgName} verbunden.</p>
      <p>
        Wenn du bei eingehenden Kunden-Nachrichten auf deinem Smartphone
        benachrichtigt werden möchtest, kannst du die Chatwoot App installieren:
      </p>

      <a
        href={"https://play.google.com/store/apps/details?id=com.chatwoot.app"}
      >
        <img
          alt="Jetzt bei Google Play"
          src="https://play.google.com/intl/en_us/badges/static/images/badges/de_badge_web_generic.png"
          width={230}
        />
      </a>

      <a href={"https://apps.apple.com/us/app/chatwoot/id1495796682"}>
        <img
          src={downloadFromAppStore}
          alt={"Download im App Store"}
          width={200}
        />
      </a>

      <p>
        Als URL musst du dann "https://chat-spot.de" eingeben. Dann kannst du
        dich mit deinen Postspot Daten einloggen.
      </p>

      {/*<p>*/}
      {/*  Alternativ kannst du Benachrichtungen über diese Website aktivieren.*/}
      {/*  Beantwortet werden können die Nachrichten aber nur über die mobile*/}
      {/*  Chatwoot App oder die Website.*/}
      {/*</p>*/}

      <div className={"actions"}>
        <Button
          className={"primary"}
          onClick={() => {
            navigate(routes.projectChatwootDashboard(projectId));
          }}
        >
          Abschließen
        </Button>
      </div>
    </StepThreeStyle>
  );
}
