/*
 *  transforms all chars in a string to lowercase and
 *  removes everything but letters and numbers. Replaces whitespaces with "-"
 */
export function parseString(str) {
  return str
    .toLowerCase()
    .replace(/\s/g, "-")
    .replace("ü", "ue")
    .replace("ä", "ae")
    .replace("ö", "oe")
    .replace(/[^a-z0-9.-]/gi, "");
}
