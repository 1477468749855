import styled from "styled-components";
import { colors } from "../../../core/constants/colors";
import { spacing } from "../../../core/constants/spacing";

export const StepsStyle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  background-color: ${colors.greys["100"]};
`;

export const StepGroup = styled.div`
  display: flex;
  align-items: center;

  .step-number {
    display: flex;
    justify-content: center;
    align-items: center;
    width: ${spacing.spaceLg};
    height: ${spacing.spaceLg};
    //padding: ${spacing.spaceMd};
    margin: 0 ${spacing.space2xs} 0 0;

    background-color: ${(props) =>
      props.completed ? props.colorCompleted : props.colorUnCompleted};
    border-radius: 99px;
    color: white;
  }

  .step-title {
    display: flex;
    align-items: center;
    line-height: ${spacing.spaceLg};

    color: ${(props) =>
      props.completed ? props.colorCompleted : props.colorUnCompleted};
  }

  .step-line {
    display: ${(props) => (props.last ? "flex" : "none")};
    width: ${spacing.space2xl};
    margin: 0 ${spacing.space2xs};
    padding-top: 2px;

    border-style: none none ${(props) => (props.completed ? "solid" : "dashed")}
      none;
    border-width: 2px;
    border-color: ${(props) =>
      props.completed ? props.colorCompleted : props.colorUnCompleted};
  }
`;
