import React from "react";
import { connect } from "react-redux";
import Sites from "./Sites";
import { setOrganizations } from "../../../core/redux/organizations/action";
import { fetchOrganizations } from "../../../core/redux/organizations/thunk";
import { fetchSites } from "../../../core/redux/organizations/thunk";
import store from "../../../core/redux/store";

const mapStateToProps = (state, props) => {
  const organization = state.organizations[Object.keys(state.organizations)[0]];
  if (!organization) return {};

  const project = organization.projects[props.projectId];
  const sites = project.sites;

  return {
    user: state.auth.user,
    organization: organization,
    project: project,
    sites: sites,
    ...props,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setOrganizations: (orgs) => {
      dispatch(setOrganizations(orgs));
    },
    fetchSites: (orgId, projectId) => {
      dispatch(fetchSites(orgId, projectId));
    },
  };
};

const SitesPage = connect(mapStateToProps, mapDispatchToProps)(Sites);

export default SitesPage;
