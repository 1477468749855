import React from "react";

const config = {
  readonly: false, // all options from https://xdsoft.net/jodit/doc/
  buttonsSM: [
    "paragraph",
    "|",
    "bold",
    "italic",
    "strikethrough",
    "underline",
    "|",
    "outdent",
    "indent",
    "|",
    "align",
    "|",
    "link",
    "|",
    "ul",
    "ol",
  ],
};

export function JoEditor({ val, handler }) {
  if (typeof window !== undefined) {
    const JoditEditor = require("jodit-react").default;
    return (
      <JoditEditor
        // ref={editor}
        value={val}
        config={config}
        onChange={handler}
      />
    );
  } else {
    return <textarea />;
  }
}
