import React, { useEffect, useState } from "react";
import Steps from "../../../../../components/Misc/Steps/Steps";
import AppLayout from "../../../../../components/Layout/AppLayout";
import { AppPage } from "../../../../../components/Misc/AppPage";
import { routes } from "../../../../../core/constants/routes";
import { colors } from "../../../../../core/constants/colors";
import { StepContent } from "./Steps/Steps";
import { navigate } from "gatsby";

export default function ChatwootSetup({
  organization,
  project,
  location,
  user,
  updateProject,
}) {
  const [currentStep, setCurrentStep] = useState(1);
  const steps = ["Accounts", "WhatsApp", "Benachrichtigung"];

  if (user.chatwootUserId) {
    navigate(routes.projectChatwootDashboard(project.id));
    return;
  }

  console.log(currentStep);

  // function getConnectionState() {
  //   authenticatedRequest({"get"})
  // }
  //
  // useEffect(() => {
  //   // fetch regulary to receive QR code and connection updated
  //   setTimeout(getConnectionState(), 1000);
  //   }, [])

  return (
    <AppLayout
      showHeader={true}
      title={"Chatwoot Setup"}
      subtitle={project?.name}
      showNavigation={true}
      location={location}
      projectId={project?.id}
      lastRoute={routes.project(project.id)}
    >
      <AppPage>
        <Steps
          currentStep={currentStep}
          steps={["WhatsApp", "Benachrichtigung"]}
          colorCompleted={colors.primary["130"]}
          colorUnCompleted={colors.greys["700"]}
        />

        <StepContent
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          projectId={project.id}
          orgId={organization.id}
          orgName={organization.name}
          organization={organization}
          project={project}
          updateProject={updateProject}
        />
      </AppPage>
    </AppLayout>
  );
}
