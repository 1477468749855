const apiBase = process.env.API_URL; //"http://localhost:3000"
const chatwoodBase = process.env.CHATWOOT_URL;

export const routes = {
  /* APP */
  app: "/",

  // Project
  project: (projectId) => `/${projectId}/`,
  projectRouter: `/:projectId/`,

  // Settings
  settings: (projectId) => `/${projectId}/settings`,
  settingsRouter: `/:projectId/settings`,

  projectWebsite: (projectId) => `/${projectId}/website`,
  projectWebsiteRouter: `/:projectId/website`,

  projectBuild: (projectId) => `/${projectId}/build`,
  projectBuildRouter: `/:projectId/build`,

  projectCodeGenerator: (projectId) => `/${projectId}/code-generator`,
  projectCodeGeneratorRouter: `/:projectId/code-generator`,

  // Plugins
  projectPlugins: (projectId) => `/${projectId}/plugins`,
  projectPluginsRouter: `/:projectId/plugins`,

  // Project Push
  projectPush: (projectId) => `/${projectId}/push`,
  projectPushRouter: `/:projectId/push`,

  // Posts
  projectPosts: (projectId) => `/${projectId}/posts`,
  projectPostsRouter: `/:projectId/posts`,

  projectPostEditor: (projectId, postId) => `/${projectId}/posts/${postId}`,
  projectPostEditorRouter: `/:projectId/posts/:postId`,

  projectDesign: (projectId) => `/${projectId}/design`,
  projectDesignRouter: `/:projectId/design`,

  // Project Site
  projectSites: (projectId) => `/${projectId}/sites`,
  projectSitesRouter: `/:projectId/sites`,

  projectSiteManager: (projectId) => `/${projectId}/sites/manager`,
  projectSiteManagerRouter: `/:projectId/sites/manager`,

  projectSiteBuilder: (projectId, siteId) => `/${projectId}/sites/${siteId}`,
  projectSiteBuilderRouter: `/:projectId/sites/:siteId`,

  projectMenuBuilder: (projectId) => `/${projectId}/menu-builder`,
  projectMenuBuilderRouter: `/:projectId/menu-builder`,

  projectFooterBuilder: (projectId) => `/${projectId}/footer-builder`,
  projectFooterBuilderRouter: `/:projectId/footer-builder`,

  projectChatwootDashboard: (projectId) => `/${projectId}/chatwoot-dashboard`,
  projectChatwootDashboardRouter: `/:projectId/chatwoot-dashboard`,

  projectCustomDomain: (projectId) => `/${projectId}/custom-domain`,
  projectCustomDomainRouter: `/:projectId/custom-domain`,

  projectPluginsCookieConsent: (projectId) => `/${projectId}/cookie-consent`,
  projectPluginsCookieConsentRouter: `/:projectId/cookie-consent`,

  projectChatwootSetup: (projectId) => `/${projectId}/chatwoot-setup`,
  projectChatwootSetupRouter: `/:projectId/chatwoot-setup`,

  chatwood: {
    base: chatwoodBase,

    account: `${chatwoodBase}/api/v1/accounts`,
  },

  /* API */
  api: {
    base: apiBase,

    rssFeed: (orgId, projectId) =>
      `${apiBase}/v1/organizations/${orgId}/projects/${projectId}/rss`,
    /*
     * Auth
     */
    login: `${apiBase}/v1/auth/login`,
    refreshToken: `${apiBase}/v1/auth/refresh-token`,

    // user
    user: (userId) => `${apiBase}/v1/users/${userId}`,
    userProfile: `${apiBase}/v1/users/profile`,

    /*
     * ORGANIZATION
     */
    organizations: `${apiBase}/v1/organizations`,
    organization: (orgId) => `${apiBase}/v1/organizations/${orgId}`,

    // Project Project
    organizationProjects: (orgId) =>
      `${apiBase}/v1/organizations/${orgId}/projects`,
    organizationProject: (orgId, projectId) =>
      `${apiBase}/v1/organizations/${orgId}/projects/${projectId}`,

    organizationProjectSetup: (orgId, projectId) =>
      `${apiBase}/v1/organizations/${orgId}/projects/${projectId}/setup`,

    organizationProjectEnv: (orgId, projectId) =>
      `${apiBase}/v1/organizations/${orgId}/projects/${projectId}/env`,

    organizationProjectDeployPreview: (orgId, projectId) =>
      `${apiBase}/v1/organizations/${orgId}/projects/${projectId}/deployPreview`,

    organizationProjectLogo: (orgId, projectId) =>
      `${apiBase}/v1/organizations/${orgId}/projects/${projectId}/logo`,

    organizationProjectBuild: (orgId, projectId) =>
      `${apiBase}/v1/organizations/${orgId}/projects/${projectId}/build`,

    organizationProjectCodeGenerator: (orgId, projectId) =>
      `${apiBase}/v1/organizations/${orgId}/projects/${projectId}/generateQrCode`,

    // Project Settings
    organizationProjectSettings: (orgId, projectId) =>
      `${apiBase}/v1/organizations/${orgId}/projects/${projectId}/settings`,

    // Project Project Post
    organizationProjectPosts: (orgId, projectId) =>
      `${apiBase}/v1/organizations/${orgId}/projects/${projectId}/posts`,
    organizationProjectPost: (orgId, projectId, postId) =>
      `${apiBase}/v1/organizations/${orgId}/projects/${projectId}/posts/${postId}`,

    // Project Project Sub
    organizationProjectSubs: (orgId, projectId) =>
      `${apiBase}/v1/organizations/${orgId}/projects/${projectId}/subs`,
    organizationProjectPush: (orgId, projectId) =>
      `${apiBase}/v1/organizations/${orgId}/projects/${projectId}/push`,

    // Project Project Site
    organizationProjectSites: (orgId, projectId) =>
      `${apiBase}/v1/organizations/${orgId}/projects/${projectId}/sites`,
    organizationProjectSite: (orgId, projectId, siteId) =>
      `${apiBase}/v1/organizations/${orgId}/projects/${projectId}/sites/${siteId}`,

    organizationProjectSaveImages: (orgId, projectId) =>
      `${apiBase}/v1/organizations/${orgId}/projects/${projectId}/saveImages`,

    /* CHATWOOT */
    organizationChatwootUser: (orgId, projectId) =>
      `${apiBase}/v1/organizations/${orgId}/chatwoot/user`,

    organizationProjectWhatsapp: (orgId, projectId) =>
      `${apiBase}/v1/organizations/${orgId}/projects/${projectId}/whatsapp`,

    organizationProjectWhatsappReset: (orgId, projectId) =>
      `${apiBase}/v1/organizations/${orgId}/projects/${projectId}/whatsapp/reset`,

    // register: `${apiBase}/v1/auth/register`,
    //
    // getUsers: `${apiBase}/v1/users`,
    // organizations: `${apiBase}/v1/organizations`,
    // organizationUsers: (orgId) => `${apiBase}/v1/organizations/${orgId}/users`,
    // organizationUser: (orgId, userId) =>
    //   `${apiBase}/v1/organizations/${orgId}/users/${userId}`,
  },

  projects: {
    userProjectSite: (projectName, siteName) =>
      `https://${projectName}.${process.env.PROJECT_DOMAIN}/${siteName}`,
  },
};
