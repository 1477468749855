import styled from "styled-components";
import { spacing } from "../../../../core/constants/spacing";
import borderRadius from "../../../../core/constants/borderRadius";
import { colors } from "../../../../core/constants/colors";
import fontSize from "../../../../core/constants/typeScale";
import breakpoints from "../../../../core/constants/breakpoints";

export const PageCreatorForm = styled.form`
  display: flex;
  flex-direction: column;

  label {
    font-weight: 400;
  }

  input {
    padding: ${spacing.space3xs} ${spacing.space2xs};
    border-width: 1px;
    border-style: solid;
  }

  button {
    margin-top: ${spacing.spaceSm};
  }
`;

export const SiteManagerStyle = styled.div`
  display: flex;
  justify-content: space-between;

  section {
    width: 49%;
  }

  .site-editor-grid {
    display: grid;
  }

  @media (max-width: ${breakpoints.medium}) {
    flex-direction: column;
    gap: ${spacing.spaceSm};

    section {
      width: 100%;
    }
  }
`;
