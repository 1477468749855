import React, { useEffect } from "react";
import AppLayout from "../../components/Layout/AppLayout";
import { routes } from "../../core/constants/routes";
import { Link } from "gatsby";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { SimpleGrid } from "../../components/Misc/SimpleGrid";
import { SendIcon } from "../../assets/icons/SendIcon";
import { GlobeIcon } from "../../assets/icons/GlobeIcon";
import { AppPage } from "../../components/Misc/AppPage";
import { setFetching } from "../../core/redux/appState/action";
import store from "../../core/redux/store";
import { ListIcon } from "../../assets/icons/ListIcon";
import { ChatIcon } from "../../assets/icons/ChatIcon";
import { PlusCircleIcon } from "../../assets/icons/PlusCircle";

export default function Home({
  path,
  fetchOrganizations,
  project,
  location,
  projectId,
}) {
  useEffect(() => {
    store.dispatch(setFetching(false));
    fetchOrganizations();
  }, []);

  return (
    <AppLayout
      path={path}
      showHeader={true}
      title={"Home"}
      location={location}
      projectId={project?.id}
    >
      <AppPage>
        <SimpleGrid>
          <Link className={"grid-link"} to={routes.projectPush(projectId)}>
            <SendIcon />
            <span>Posten</span>
          </Link>

          <a
            className={"grid-link"}
            href={routes.chatwood.base}
            target={"_blank"}
          >
            <ChatIcon />
            <span>Chat</span>
          </a>

          <Link className={"grid-link"} to={routes.projectWebsite(projectId)}>
            <GlobeIcon />
            <span>Website</span>
          </Link>

          <Link className={"grid-link"} to={routes.projectPlugins(projectId)}>
            <PlusCircleIcon stroke={"currentColor"} />
            <span>Plugins</span>
          </Link>

          <Link className={"grid-link"} to={routes.projectPosts(projectId)}>
            <ListIcon stroke={"currentColor"} />
            <span>Posts</span>
          </Link>

          <Link className={"grid-link"} to={routes.settings(projectId)}>
            <GlobeIcon />
            <span>Einstellungen</span>
          </Link>
        </SimpleGrid>
      </AppPage>
    </AppLayout>
  );
}
