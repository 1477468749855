import React, { useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { SiteCardContainer } from "./styled";
import { MoveIcon } from "../../../../../assets/icons/MoveIcon";
import { colors } from "../../../../../core/constants/colors";
import { FilePlusIcon } from "../../../../../assets/icons/FilePlus";
import { authenticatedRequest } from "../../../../../core/services/authenticatedRequest";
import { routes } from "../../../../../core/constants/routes";
import * as Sentry from "@sentry/gatsby";

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

function updateIndices(sitesArray) {
  sitesArray.forEach((site, index) => {
    site.index = index;
  });

  return sitesArray;
}

function compare(a, b) {
  if (a.index < b.index) return -1;
  if (a.index > b.index) return 1;
  if (a.index === b.index) return 0;
}

function sitesObjectToArray(sitesObject) {
  console.log(sitesObject);

  let sitesArray = [];
  Object.keys(sitesObject).forEach((key, index) => {
    const site = sitesObject[key];
    sitesArray.push(site);
  });

  sitesArray.sort(compare);

  sitesArray = updateIndices(sitesArray);

  // remove empty slots & return
  return sitesArray;
}

export default function SiteListComponent({
  selectSite,
  project,
  addProjectSite,
  organization,
  forceUpdate,
  updateProjectSite,
}) {
  const [sites, setSites] = useState(sitesObjectToArray(project.sites));
  const [newSiteIndex, setNewSiteIndex] = useState(
    Object.keys(project.sites).length
  );

  // on site-add, project.sites changes and this is triggered
  // on site delete, this doens'T work, although the site is correctly removed from redux..
  useEffect(() => {
    setSites(sitesObjectToArray(project.sites));
  }, [project.sites, forceUpdate]);

  function onDragEnd(result) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    let newSites = reorder(
      sites,
      result.source.index,
      result.destination.index
    );

    // update indexes
    newSites = updateIndices(newSites);

    setSites(newSites);
    saveNewOrder();
  }

  function saveNewOrder() {
    sites.forEach((site, index) => {
      // mongoose throws 404 if requests are sent at same time
      window.setTimeout(() => {
        authenticatedRequest({
          method: "put",
          url: routes.api.organizationProjectSite(
            organization.id,
            project.id,
            site.id
          ),
          data: {
            index: site.index,
          },
        })
          .then((r) => {
            updateProjectSite(organization.id, project.id, site.id, {
              index: r.data.index,
            });
          })

          .catch((err) => console.error(err));
      }, index * 150);
    });
  }

  function createNewSite() {
    const orgId = organization.id;
    const projectId = project.id;

    setNewSiteIndex((prev) => prev + 1);
    authenticatedRequest({
      method: "post",
      url: routes.api.organizationProjectSites(orgId, projectId),
      data: {
        name: "Neue Seite",
        index: newSiteIndex,
        showMenu: true,
        showFooter: true,
        showLinkInFooter: false,
        showLinkInMenu: true,
      },
    })
      .then((result) => {
        addProjectSite(organization.id, project.id, result.data);
      })
      .catch((err) => {
        setNewSiteIndex((prev) => prev - 1);
        console.error(err);
        Sentry.captureException(err);
      });
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(provided, snapshot) => (
          <SiteCardContainer
            {...provided.droppableProps}
            ref={provided.innerRef}
          >
            {sites.map((site, index) => {
              // skip empty slots
              if (!site) return;
              return (
                <Draggable key={site.id} draggableId={site.id} index={index}>
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      className={"site-card"}
                      onClick={() => selectSite(site.id)}
                    >
                      <div
                        {...provided.dragHandleProps}
                        className={"site-card-icon"}
                      >
                        <MoveIcon
                          height={"20x"}
                          width={"20px"}
                          stroke={colors.greys["600"]}
                        />
                      </div>

                      <span className={"site-name"}>{site.name}</span>
                    </div>
                  )}
                </Draggable>
              );
            })}
            {provided.placeholder}
            <div className={"site-card new-site-card"} onClick={createNewSite}>
              <div className={"site-card-icon"}>
                <FilePlusIcon />
              </div>
              <span className={"site-name"}>Neue Seite</span>
            </div>
          </SiteCardContainer>
        )}
      </Droppable>
    </DragDropContext>
  );
}
