import styled from "styled-components";
import { spacing } from "../../core/constants/spacing";
import { colors } from "../../core/constants/colors";
import borderRadius from "../../core/constants/borderRadius";
import fontSize from "../../core/constants/typeScale";
import dimensions from "../../core/constants/dimensions";
import breakpoints from "../../core/constants/breakpoints";

export const Form = styled.form`
  width: auto;
  display: flex;
  flex-direction: column;
  padding: 0 ${spacing.spaceSm} ${spacing.spaceSm} ${spacing.spaceSm};

  border-style: solid;
  border-width: 1px;
  border-color: ${colors.greys["200"]};
  // border-radius: ${borderRadius.small};

  .input-group,
  .input-group-checkbox,
  textarea + * {
    margin-top: ${spacing.spaceSm};
  }

  .form-section {
    margin-bottom: ${spacing.spaceXs};
  }

  .input-group-checkbox {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    input {
      width: 24px;
      min-width: unset;
      height: 24px;
      margin: 0 ${spacing.spaceXs} 0 0;
    }
  }

  .input-group {
    display: flex;
    flex-direction: column;

    max-width: ${spacing.space7xl};

    p {
      margin: 0; //${spacing.spaceXs} 0;
    }
  }

  .input-row {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  .image-preview {
    margin-top: ${spacing.spaceMd};
    max-height: ${spacing.space5xl};
    width: auto;
    object-fit: contain;
  }

  label {
    font-weight: 500;
  }

  input,
  textarea,
  select,
  .wysiwyg {
    margin-top: ${spacing.space3xs};
    padding: ${spacing.space2xs} ${spacing.spaceXs};
    min-width: ${spacing.space6xl};

    border-style: solid;
    border-width: 1px;
    border-color: ${colors.greys["300"]};
    //border-radius: ${borderRadius.small};
    box-sizing: content-box;

    font-size: ${fontSize.textMd};
  }

  .button-row {
    display: flex;
    justify-content: space-between;
    margin-top: ${spacing.spaceSm};

    .message {
      color: red;
    }

    button {
      align-self: center;
    }
  }

  /* SECTIONING */

  hr {
    margin-bottom: ${spacing.spaceXs};
  }

  h4 {
    font-weight: 500;
    margin: 0 0 ${spacing.spaceXs} 0;
  }

  @media (max-width: ${breakpoints.small}) {
    border-style: none;
    padding: 0;
  }
`;
