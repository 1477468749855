import React, { useEffect, useState } from "react";
import { Style } from "./styled";
import { Form } from "../../Misc/Form";
import { Button } from "../../Misc/Button";
import { StaticImage } from "gatsby-plugin-image";
import { authenticatedRequest } from "../../../core/services/authenticatedRequest";
import { routes } from "../../../core/constants/routes";
import { parseColor } from "../../../core/services/parseColor";
import { arrayToObject } from "../../../core/services/arrayToObject";

const initialState = {
  // general
  contentWidth: "1200px",
  fullWidth: "100%",
  fontFamily: "",
  // menu
  menuBackgroundColor: "#333333",
  menuLinkAlign: "0",
  menuLogoWidth: "64px",
  menuFixed: false,
  // footer
  footerBackgroundColor: "#333333",
  // content
  contentBackgroundColor: "#ffffff",
};

export default function LayoutSettingsComponent({
  isOpen,
  close,
  orgId,
  project,
  updateProject,
  mode,
  forceUpdate,
}) {
  const [state, setState] = useState({ ...initialState, ...project.layout });

  useEffect(() => {
    if (isOpen) {
      let actualWidth = null;
      const logoImage = document.querySelector(".logo-image");
      if (mode === "menu" && logoImage) {
        actualWidth = logoImage.style.width;
      }
      setState({
        ...initialState,
        ...project.layout,
        menuLogoWidth:
          actualWidth || project.layout.menuLogoWidth || state.menuLogoWidth,
      });
    }
  }, [isOpen]);

  /* this is triggered by the builder apply the css
        it is needed, because f.e. the content background-color is not applied to the html,
        but added at build time.
     */
  useEffect(() => {
    updateBuilder(state);
  }, [forceUpdate]);

  // write settings into builder html
  function updateBuilder(state) {
    switch (mode) {
      case "menu": {
        // Menu Container
        const menuContainerNodes = document.querySelectorAll(".menu-container");
        menuContainerNodes.forEach((node) => {
          node.style.backgroundColor = state.menuBackgroundColor;
          node.style.maxWidth = state.fullWidth;
        });

        // Nav Container
        const navContainerNodes = document.querySelectorAll(".nav-container");
        navContainerNodes.forEach((node) => {
          node.style.maxWidth = state.contentWidth;
        });

        // Nav
        const navNodes = document.querySelectorAll(".nav");
        navNodes.forEach((node) => {
          node.style.margin = state.menuLinkAlign;
        });

        // Logo
        const logoNodes = document.querySelectorAll(".logo-image");
        logoNodes.forEach((node) => {
          node.style.width = state.menuLogoWidth;
        });
        break;
      }

      case "site": {
        const containerNode = document.querySelectorAll(".is-wrapper");
        // const boxNode = document.querySelectorAll(".is-boxes");
        const overlayNode = document.querySelectorAll(".is-overlay");
        containerNode.forEach((node) => {
          node.style.backgroundColor = state.contentBackgroundColor;
          // node.style.width = `calc(${state.fullWidth} - 51px`;
          // node.style.marginLeft = "51px";
        });
        // boxNode.forEach((node) => {
        //   node.style.backgroundColor = state.contentBackgroundColor;
        // });
        // overlayNode.forEach((node) => {
        //   node.style.backgroundColor = "";
        // });
        const rows = document.querySelectorAll(".is-boxes");
        rows.forEach((node) => {
          node.style.maxWidth = state.contentWidth;
        });
        // const rows2 = document.querySelectorAll(".is-box-image");
        // rows2.forEach((node) => {
        //   node.style.maxWidth = state.contentWidth;
        // });
        // const wrapper = document.querySelectorAll(".is-wrapper");
        // wrapper.forEach((node) => {
        //   node.style.width = state.fullWidth;
        // });
        const sections = document.querySelectorAll(".is-section");
        sections.forEach((node) => {
          node.style.display = "flex";
          node.style.justifyContent = "center";
        });
        break;
      }

      case "footer": {
        const containerNode = document.querySelectorAll(".is-container");
        containerNode.forEach((node) => {
          node.style.backgroundColor = state.footerBackgroundColor;
        });
        break;
      }
    }

    /* ALWAYS */
    // fontfamily
    const containerNode = document.querySelectorAll(".is-wrapper");
    containerNode.forEach((node) => {
      node.style.fontFamily = state.fontFamily;
    });
  }

  function onSubmit(event) {
    event.preventDefault();

    updateBuilder(state);
    close();

    console.log(state);

    let dataToSend;
    switch (mode) {
      case "menu": {
        dataToSend = {
          menuBackgroundColor: parseColor(state.menuBackgroundColor),
          menuLinkAlign: state.menuLinkAlign,
          menuLogoWidth: state.menuLogoWidth,
          menuFixed: state.menuFixed,
        };
        break;
      }
      case "site": {
        dataToSend = {
          contentBackgroundColor: parseColor(state.contentBackgroundColor),
        };
        break;
      }
      case "footer": {
        dataToSend = {
          footerBackgroundColor: parseColor(state.footerBackgroundColor),
        };
        break;
      }
    }

    dataToSend = {
      ...dataToSend,
      contentWidth: state.contentWidth,
      fullWidth: state.fullWidth,
    };

    authenticatedRequest({
      method: "put",
      url: routes.api.organizationProject(orgId, project.id),
      data: {
        layout: dataToSend,
      },
    })
      .then((r) => {
        updateProject(orgId, project.id, {
          ...r.data,
          sites: arrayToObject(r.data.sites),
        });
      })
      .catch((err) => console.error(err));
  }

  function onChange(event) {
    if (event.target.type === "checkbox") {
      setState((prevState) => ({
        ...prevState,
        [event.target.name]: event.target.checked,
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        [event.target.name]: event.target.value,
      }));
    }

    updateBuilder(state);
  }

  function onFontChange(newFont) {
    setState((prevState) => ({
      ...prevState,
      fontFamily: newFont.family,
    }));

    updateBuilder(state);
  }

  let FontPicker;
  if (typeof window !== "undefined") {
    FontPicker = require("font-picker-react").default;
  }

  return (
    <Style open={isOpen}>
      <div className={"columns"}>
        <div className={"cl-left"}>
          <StaticImage
            src={"../../../assets/Builder/SiteSkeleton.jpg"}
            alt="A dinosaur"
            placeholder="blurred"
            layout="constrained"
            width={300}
          />
        </div>

        {/* General */}
        <div className={"cl-right"}>
          <Form onSubmit={onSubmit}>
            <div className={"form-section"}>
              <div className={"input-group"}>
                <label htmlFor={"fullWidth"}>
                  Globale Section Breite (in px, %, ...):
                </label>
                <input
                  id={"fullWidth"}
                  type={"text"}
                  value={state.fullWidth}
                  name={"fullWidth"}
                  onChange={onChange}
                  defaultValue={"100%"}
                />
              </div>
              <div className={"input-group"}>
                <label htmlFor={"contentWidth"}>
                  Inhalt Breite (in px, %, ...):
                </label>
                <input
                  id={"contentWidth"}
                  type={"text"}
                  value={state.contentWidth}
                  name={"contentWidth"}
                  onChange={onChange}
                />
              </div>
              {/*the font picker crashes the build, because it's using a function*/}
              {/*on document*/}
              {typeof window !== "undefined" ? (
                <div className={"input-group"}>
                  <label htmlFor={"fontFamily"}>Schriftart</label>
                  <FontPicker
                    apiKey={process.env.GOOGLE_FONTS_API_KEY}
                    activeFontFamily={state.fontFamily || "Open Sans"}
                    id={"fontFamily"}
                    name={"fontFamily"}
                    onChange={(nextFont) => onChange(nextFont)}
                    limit={150}
                    // sort={"popularity"}
                  />
                </div>
              ) : (
                ""
              )}
            </div>

            {/* Menu */}
            <div
              className={"form-section"}
              style={{ display: mode === "menu" ? "block" : "none" }}
            >
              <h4>Menü</h4>
              <div className={"input-group"}>
                <label htmlFor={"menuBackgroundColor"}>
                  Hintergrund Farbe:
                </label>
                <input
                  id={"menuBackgroundColor"}
                  type={"color"}
                  name={"menuBackgroundColor"}
                  value={state.menuBackgroundColor}
                  onChange={onChange}
                  defaultValue={state.menuBackgroundColor}
                  disabled={mode !== "menu"}
                />
              </div>
              <div className={"input-group"}>
                <label htmlFor={"linkAlign"}>Link Position:</label>
                <select
                  id={"linkAlign"}
                  name={"menuLinkAlign"}
                  value={state.menuLinkAlign}
                  onChange={onChange}
                  defaultValue={"0"}
                  disabled={mode !== "menu"}
                >
                  <option value={"0 auto 0 1%"}>Links</option>
                  <option value={"0 auto"}>Zentriert</option>
                  <option value={"0 1% 0 auto"}>Rechts</option>
                </select>
              </div>
              <div className={"input-group"}>
                <label htmlFor={"menuLogoWidth"}>Logo Breite</label>
                <input
                  id={"menuLogoWidth"}
                  type={"text"}
                  value={state.menuLogoWidth}
                  name={"menuLogoWidth"}
                  onChange={onChange}
                  disabled={mode !== "menu"}
                />
              </div>

              <div className={"input-group-checkbox"}>
                <input
                  id={"menuFixed"}
                  type={"checkbox"}
                  checked={state.menuFixed}
                  name={"menuFixed"}
                  onChange={onChange}
                  disabled={mode !== "menu"}
                />
                <label htmlFor={"menuFixed"}>Menü fixieren</label>
              </div>
            </div>

            {/* Site */}
            <div
              className={"form-section"}
              style={{ display: mode === "site" ? "block" : "none" }}
            >
              <h4>Seiteninhalt</h4>
              <div className={"input-group"}>
                <label htmlFor={"contentBackgroundColor"}>
                  Hintergrund Farbe:
                </label>
                <input
                  id={"contentBackgroundColor"}
                  type={"color"}
                  name={"contentBackgroundColor"}
                  value={state.contentBackgroundColor}
                  onChange={onChange}
                  disabled={mode !== "site"}
                />
              </div>
            </div>

            {/*Footer */}
            <div
              className={"form-section"}
              style={{ display: mode === "footer" ? "block" : "none" }}
            >
              <h4>Footer</h4>
              <div className={"input-group"}>
                <label htmlFor={"footerBackgroundColor"}>
                  Hintergrund Farbe:
                </label>
                <input
                  id={"footerBackgroundColor"}
                  type={"color"}
                  name={"footerBackgroundColor"}
                  value={state.footerBackgroundColor}
                  onChange={onChange}
                  disabled={mode !== "footer"}
                />
              </div>
            </div>

            <div className={"form-section"}>
              <div className={"button-row"}>
                <Button
                  type={"button"}
                  className={"secondary"}
                  onClick={() => {
                    updateBuilder(project.layout);
                    close();
                  }}
                >
                  Abbrechen
                </Button>
                <Button type={"submit"} className={"primary"}>
                  Speichern
                </Button>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </Style>
  );
}
