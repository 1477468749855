import React, { useState } from "react";
import { routes } from "../../../../core/constants/routes";
import AppLayout from "../../../../components/Layout/AppLayout";
import { AppPage } from "../../../../components/Misc/AppPage";
import { Form } from "../../../../components/Misc/Form";
import { Button } from "../../../../components/Misc/Button";
import axios from "axios";
import { QrCode } from "../../../Website/Build/styled";
import Collapse, { Panel } from "rc-collapse";
import { DownloadIcon } from "../../../../assets/icons/DownloadIcon";

export default function CodeGeneratorComponent({
  organization,
  project,
  location,
}) {
  require("rc-collapse/assets/index.css");

  const [state, setState] = useState({
    url: "",
    darkColor: "#000000",
    lightColor: "#ffffff",
    margin: 4,
    scale: 8,
    saveForSnippet: false,
    urlParam: true,
  });
  const [result, setResult] = useState({
    // permanent link to qr code
    projectUrl: "",
    // temporary preview (needed for download, bc only possible on same origin)
    previewUrl: "",
    // text, for accessibility
    text: "",
  });

  function onChange(event) {
    if (event.target.type === "checkbox") {
      setState((prevState) => ({
        ...prevState,
        [event.target.name]: event.target.checked,
      }));
      return;
    }

    setState((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  }

  function onSubmit(event) {
    event.preventDefault();

    const url = state.urlParam ? `${state.url}?source=qrcode` : state.url;

    axios
      .post(
        routes.api.organizationProjectCodeGenerator(
          organization.id,
          project.id
        ),
        { ...state, url }
      )
      .then((result) => {
        setResult(result.data);
      });
  }

  return (
    <AppLayout
      showHeader={true}
      title={"QR-Code Generator"}
      subtitle={project.name}
      showNavigation={true}
      lastRoute={routes.projectPlugins(project.id)}
      location={location}
      projectId={project.id}
    >
      <AppPage>
        <Form onSubmit={onSubmit}>
          <div className={"input-group"}>
            <label htmlFor={"input-url"}>URL</label>
            <input
              type={"text"}
              name={"url"}
              id={"input-url"}
              onChange={onChange}
              value={state.url}
              required={true}
            />
          </div>

          <div className={"input-group"}>
            <Collapse accordion={true}>
              <Panel
                header={"Weitere Einstellungen"}
                headerClass="my-header-class"
              >
                <div className={"input-group"}>
                  <label htmlFor={"input-color-dark"}>Farbe (dunkel)</label>
                  <input
                    type={"color"}
                    name={"darkColor"}
                    id={"input-color-dark"}
                    onChange={onChange}
                    value={state.darkColor}
                  />
                </div>

                <div className={"input-group"}>
                  <label htmlFor={"input-color-light"}>Farbe (hell)</label>
                  <input
                    type={"color"}
                    name={"lightColor"}
                    id={"input-color-light"}
                    onChange={onChange}
                    value={state.lightColor}
                  />
                </div>

                <div className={"input-group"}>
                  <label htmlFor={"input-margin"}>Ruhezone (0-20)</label>
                  <input
                    type={"number"}
                    name={"margin"}
                    id={"input-margin"}
                    onChange={onChange}
                    value={state.margin}
                    max={20}
                    min={0}
                  />
                </div>

                <div className={"input-group"}>
                  <label htmlFor={"input-scale"}>Größe (1-15)</label>
                  <input
                    type={"number"}
                    name={"scale"}
                    id={"input-scale"}
                    onChange={onChange}
                    value={state.scale}
                    min={1}
                    max={15}
                  />
                </div>

                <div className={"input-group-checkbox"}>
                  <input
                    type={"checkbox"}
                    id={"input-url-param"}
                    name={"urlParam"}
                    checked={state.urlParam}
                    onChange={onChange}
                  />
                  <label htmlFor={"input-url-param"}>
                    URL Parameter "?source=qrcode" anhängen
                  </label>
                </div>
              </Panel>
            </Collapse>
          </div>

          <div className={"input-group-checkbox"}>
            <input
              type={"checkbox"}
              id={"saveForSnippet"}
              name={"saveForSnippet"}
              checked={state.saveForSnippet}
              onChange={onChange}
            />
            <label htmlFor={"saveForSnippet"}>
              Als Standard für das Builder Snippet speichern
            </label>
          </div>

          <div className={"button-row"}>
            <Button
              className={"primary"}
              type={"submit"}
              download={"qrcode.png"}
            >
              QR-Code generieren
            </Button>
          </div>
        </Form>

        <QrCode display={result.projectUrl ? "flex" : "none"}>
          <h2>QR Code</h2>
          <img
            src={result.projectUrl}
            alt={`QR Code, Inhalt: ${result.text}`}
          />

          <a
            href={result.previewUrl}
            download={true}
            className={"download-link-button"}
          >
            <DownloadIcon />
            Download
          </a>
        </QrCode>
      </AppPage>
    </AppLayout>
  );
}
