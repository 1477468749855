import React from "react";
import { connect } from "react-redux";
import ChatwootDashboard from "./ChatwootDashboard";
import store from "../../../../../core/redux/store";
import { patchUser } from "../../../../../core/redux/auth/action";
import { fetchOrganizations } from "../../../../../core/redux/organizations/thunk";

const mapStateToProps = (state, props) => {
  const organization = state.organizations[Object.keys(state.organizations)[0]];
  if (!organization) return {};

  const project = organization.projects[props.projectId];

  return {
    user: state.auth.user,
    organization: organization,
    project: project,
    location: props.location,
    ...props,
  };
};

const mapDispatchToProps = (dispatch) => {
  const state = store.getState();
  return {
    patchUser: (patch) => {
      dispatch(patchUser(patch));
    },
    fetchOrganizations: () => {
      dispatch(fetchOrganizations());
    },
  };
};

const ChatwootDashboardPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(ChatwootDashboard);

export default ChatwootDashboardPage;
