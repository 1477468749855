import React from "react";

export const MenuIcon = ({
  fill,
  stroke,
  strokeLinecap,
  strokeLineJoin,
  strokeWidth,
}) => {
  const style = {
    fill: fill || "currentColor",
    stroke: stroke || "currentColor",
    strokeLinecap: strokeLinecap || "round",
    strokeLineJoin: strokeLineJoin || "round",
    strokeWidth: strokeWidth || "32px",
  };
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="512"
      height="512"
      viewBox="0 0 512 512"
      style={style}
    >
      <line x1="80" y1="160" x2="432" y2="160" style={style} />
      <line x1="80" y1="256" x2="432" y2="256" style={style} />
      <line x1="80" y1="352" x2="432" y2="352" style={style} />
    </svg>
  );
};
