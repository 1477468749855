import React from "react";
import { connect } from "react-redux";
import ProjectSettings from "./FooterBuilder";
import { handleLogout } from "../../../core/services/auth";
import FooterBuilder from "./FooterBuilder";
import { updateProject } from "../../../core/redux/organizations/action";
import store from "../../../core/redux/store";
import { fetchOrganizations } from "../../../core/redux/organizations/thunk";
import { patchUser } from "../../../core/redux/auth/action";

const mapStateToProps = (state, props) => {
  const organization = state.organizations[Object.keys(state.organizations)[0]];
  if (!organization) return {};

  const project = organization.projects[props.projectId];
  const siteId = props.siteId;
  const site = project.sites?.[siteId];

  return {
    user: state.auth.user,
    organization: organization,
    project: project,
    site: site,
    location: props.location,
    ...props,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateProject: (orgId, projectId, patch) =>
      dispatch(updateProject(orgId, projectId, patch)),
    patchUser: (patch) => dispatch(patchUser(patch)),
  };
};

const FooterBuilderPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(FooterBuilder);

export default FooterBuilderPage;
