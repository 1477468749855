import React from "react";
import GlobalStyle from "./GlobalStyle";
import ReduxWrapper from "../../core/redux/ReduxWrapper";
import { QueryClient, QueryClientProvider, useQuery } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";

const queryClient = new QueryClient();

const Layout = ({ children }) => (
  <QueryClientProvider client={queryClient}>
    <ReduxWrapper>
      <GlobalStyle />
      {children}
    </ReduxWrapper>
    <ReactQueryDevtools initialIsOpen />
  </QueryClientProvider>
);

export default Layout;
