/*
 * ACTION TYPES
 */

export const SET_APP_STATE = "SET_APP_STATE";
export const SET_FETCHING = "SET_FETCHING";

/*
 * ACTION CREATORS
 */

export function setAppState(newState) {
  return {
    type: SET_APP_STATE,
    newState: newState,
  };
}

export function setFetching(fetching) {
  return {
    type: SET_FETCHING,
    fetching: fetching,
  };
}
