import styled from "styled-components";
import { colors } from "../../../../../core/constants/colors";
import { spacing } from "../../../../../core/constants/spacing";
import { Form } from "../../../../../components/Misc/Form";

export const Style = styled.div``;

export const EditorPlaceholder = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  min-height: ${spacing.space4xl};

  border-style: dashed;
  border-width: 2px;
  border-color: ${colors.greys["300"]};
`;

export const SitePreview = styled.div`
  flex-grow: 2;

  iframe {
    width: 200%;
    height: 200%;
    min-height: ${spacing.space8xl};

    -ms-zoom: 0.5;
    -moz-transform: scale(0.5);
    -moz-transform-origin: 0 0;
    -o-transform: scale(0.5);
    -o-transform-origin: 0 0;
    -webkit-transform: scale(0.5);
    -webkit-transform-origin: 0 0;
  }
`;

export const SiteEditorStyle = styled.div`
  display: flex;
  flex-direction: column;

  .edit-form {
    flex-grow: 0;
  }

  hr {
    margin: ${spacing.spaceSm} 0;
  }

  ${Form} {
    //display: contents;

    .input-group {
      width: 100%;
      max-width: unset;
    }
  }
`;
