/*
 * ACTION TYPES
 */

import { refreshAndCall } from "../../services/auth";
import axios from "axios";
import { routes } from "../../constants/routes";
import store from "../store";

export const SET_ORGANIZATIONS = "SET_ORGANIZATIONS";
export const ADD_ORGANIZATION = "ADD_ORGANIZATION";
export const UPDATE_PROJECT = "UPDATE_PROJECT";
export const ADD_PROJECT_SITE = "ADD_PROJECT_SITE";
export const UPDATE_PROJECT_SITE = "UPDATE_SITE";
export const REMOVE_PROJET_SITE = "REMOVE_PROJECT_SITE";
export const UPDATE_PROJECT_POST = "UPDATE_PROJECT_POST";

/*
 * ACTION CREATORS
 */

export function setOrganizations(organizations) {
  return {
    type: SET_ORGANIZATIONS,
    organizations: organizations,
  };
}

export function addOrganization(organization) {
  return {
    type: ADD_ORGANIZATION,
    organization: organization,
  };
}

export function updateProject(orgId, projectId, patch) {
  return {
    type: UPDATE_PROJECT,
    orgId: orgId,
    projectId: projectId,
    patch: patch,
  };
}

export function addProjectSite(orgId, projectId, site) {
  return {
    type: ADD_PROJECT_SITE,
    orgId: orgId,
    projectId: projectId,
    site: site,
  };
}

export function updateProjectSite(orgId, projectId, siteId, patch) {
  return {
    type: UPDATE_PROJECT_SITE,
    orgId: orgId,
    projectId: projectId,
    siteId: siteId,
    patch: patch,
  };
}

export function removeProjectSite(orgId, projectId, siteId) {
  return {
    type: REMOVE_PROJET_SITE,
    orgId: orgId,
    projectId: projectId,
    siteId: siteId,
  };
}

export function updateProjectPost(orgId, projectId, postId, patch) {
  return {
    type: UPDATE_PROJECT_POST,
    orgId: orgId,
    projectId: projectId,
    postId: postId,
    patch: patch,
  };
}
