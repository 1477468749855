import React, { useEffect, useRef, useState } from "react";
import AppLayout from "../../../components/Layout/AppLayout";
import { routes } from "../../../core/constants/routes";
import { Form } from "../../../components/Misc/Form";
import { Button } from "../../../components/Misc/Button";
import { PostEditorStyle } from "./styled";
import { authenticatedRequest } from "../../../core/services/authenticatedRequest";
import * as Sentry from "@sentry/gatsby";
import { navigate } from "gatsby";
import { JoEditor } from "../../../components/Misc/JoEditor";

export default function PostEditor({
  organization,
  project,
  projectId,
  location,
  post,
  postId,
  setAppState,
  updatePost,
}) {
  const initialState = {
    title: post.title,
    description: post.description,
    descriptionHtml: post.descriptionHtml,
    image: post.image,
  };
  const [state, setState] = useState(initialState);
  const [fileInputChange, setFileInputChange] = useState(false);
  const fileRef = useRef();
  const [loading, setLoading] = useState(false);

  // useEffect(() => {
  // const MediumEditor = require("medium-editor");
  // const editor = new MediumEditor(".wysiwyg", {
  //   placeholder: {
  //     text: "Text eingeben",
  //     hideOnClick: true,
  //   },
  //   toolbar: {
  //     allowMultiParagraphSelection: true,
  //     buttons: [
  //       "bold",
  //       "italic",
  //       "underline",
  //       "strikethrough",
  //       "anchor",
  //       "justifyLeft",
  //       "justifyCenter",
  //       "justifyRight",
  //       "h1",
  //       "h2",
  //       "h3",
  //     ],
  //   },
  // });

  //   editor.subscribe("editableInput", (event, editable) => {
  //     setState((prevState) => ({
  //       ...prevState,
  //       descriptionHtml: editable.innerHTML,
  //       description: editable.innerText.replace(/\n/g, " "),
  //     }));
  //   });
  // }, []);

  useEffect(() => {
    try {
      if (fileRef.current.files && fileRef.current.files[0]) {
        const reader = new FileReader();
        reader.onload = (event) => {
          setState((prevState) => ({
            ...prevState,
            image: event.target.result,
          }));
        };
        reader.readAsDataURL(fileRef.current.files[0]);
      }
    } catch (err) {
      Sentry.captureException(err);
      console.error(err);
    }
  }, [fileInputChange]);

  function onChange(event) {
    setState((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  }

  function onEditorChange(newValue) {
    setState((prevState) => ({
      ...prevState,
      descriptionHtml: newValue,
    }));
  }

  function onSubmit(event) {
    event.preventDefault();

    setAppState({
      loading: true,
      statusMessage: "",
      error: false,
    });

    authenticatedRequest({
      method: "put",
      url: routes.api.organizationProjectPost(
        organization.id,
        project.id,
        postId
      ),
      data: state,
    })
      .then((result) => {
        setAppState({
          loading: false,
          statusMessage: "Änderungen gespeichert.",
          error: false,
        });
        updatePost(organization.id, projectId, postId, result.data);
        setState(result.data);
      })
      .catch((error) => {
        setAppState({
          loading: false,
          statusMessage: "Beim Speichern ist ein Fehler aufgetreten.",
          error: true,
        });
        console.error(error);
        Sentry.captureException(error);
      });
  }

  function onDelete() {
    if (!window.confirm("Möchtest du diesen Post wirklich löschen?")) {
      return;
    }
    setAppState({
      loading: true,
      statusMessage: "",
      error: false,
    });

    authenticatedRequest({
      method: "delete",
      url: routes.api.organizationProjectPost(
        organization.id,
        project.id,
        postId
      ),
      data: state,
    })
      .then((result) => {
        setAppState({
          loading: false,
          statusMessage: "Post gelöscht.",
          error: false,
        });
        navigate(routes.projectPosts(projectId));
      })
      .catch((error) => {
        setAppState({
          loading: false,
          statusMessage: "Beim Löschen ist ein Fehler aufgetreten.",
          error: true,
        });
        console.error(error);
        Sentry.captureException(error);
      });
  }

  // require("medium-editor/dist/css/medium-editor.min.css");
  // require("medium-editor/dist/css/themes/default.min.css");

  return (
    <AppLayout
      title={"PostEditor"}
      subtitle={project.name}
      showHeader={true}
      showNavigation={true}
      lastRoute={routes.projectPosts(project.id)}
      location={location}
      projectId={project.id}
    >
      <PostEditorStyle>
        <Form onSubmit={onSubmit}>
          <div className={"input-group"}>
            <label htmlFor={"push-photo"}>Foto</label>
            <input
              id="push-photo"
              className={"foto-input"}
              type="file"
              name={"imageSrc"}
              ref={fileRef}
              accept={"image/png, image/jpeg"}
              onChange={() => {
                setFileInputChange(!fileInputChange);
              }}
            />
            {state.image ? (
              <img
                id="image"
                src={state.image}
                alt="profile picture"
                className={"image-preview"}
              />
            ) : (
              ""
            )}
          </div>

          <div className={"input-group"}>
            <label htmlFor={"titleInput"}>Titel</label>
            <input
              id={"titleInput"}
              type={"text"}
              name={"title"}
              value={state.title}
              onChange={onChange}
            />
          </div>

          <div className={"input-group"}>
            <label htmlFor={"push-description"}>Beschreibung</label>
            <JoEditor
              val={state.descriptionHtml}
              handler={(newContent) => {
                setState((prevState) => ({
                  ...prevState,
                  descriptionHtml: newContent,
                }));
              }}
            />
          </div>

          <div className={"button-row"}>
            <Button className={"red"} type={"button"} onClick={onDelete}>
              Post löschen
            </Button>
            <Button className={"primary"}>Speichern</Button>
          </div>
        </Form>{" "}
      </PostEditorStyle>
    </AppLayout>
  );
}
