import styled from "styled-components";
import { spacing } from "../../../core/constants/spacing";
import { colors } from "../../../core/constants/colors";
import { AppPage } from "../../../components/Misc/AppPage";
import { Form } from "../../../components/Misc/Form";

export const PostEditorStyle = styled(AppPage)`
  ${Form} {
    .input-group {
      max-width: unset;
    }

    .button-row {
      display: flex;
    }
  }
`;
