import styled from "styled-components";
import { colors } from "../../../../../core/constants/colors";
import { spacing } from "../../../../../core/constants/spacing";

export const SiteCardContainer = styled.div`
  display: inline-flex;
  flex-direction: column;
  padding: ${spacing.space2xs};
  width: 100%;
  box-sizing: border-box;

  background-color: ${colors.greys["100"]};
  border-style: solid;
  border-color: ${colors.greys["300"]};
  border-width: 1px;

  .site-card + .site-card {
    margin-top: ${spacing.space2xs};
  }

  .site-card {
    display: inline-flex;
    align-items: center;
    min-width: ${spacing.space6xl};

    background-color: white;
    cursor: pointer;

    .site-card-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: ${spacing.space2xs} ${spacing.space2xs};
      width: ${spacing.spaceLg};
      height: ${spacing.spaceLg};
    }

    .site-name {
      padding: ${spacing.space2xs} 0;

      font-weight: 400;
    }
  }

  .new-site-card {
    display: inline-flex;
    align-items: center;
    min-width: ${spacing.space6xl};
    margin-top: ${spacing.spaceLg} !important;

    background-color: transparent;
    border-style: dashed;
    border-width: 2px;
    border-color: ${colors.greys["300"]};
    cursor: pointer;

    &:hover,
    &:focus {
      background-color: white;
    }
  }
`;
