import React from "react";
import { connect } from "react-redux";
import Home from "./Home";
import {
  setOrganizations,
} from "../../core/redux/organizations/action";
import { fetchOrganizations } from "../../core/redux/organizations/thunk";
import { routes } from "../../core/constants/routes";
import { navigate } from "gatsby";

const mapStateToProps = (state, props) => {
  const organization = state.organizations[Object.keys(state.organizations)[0]];
  const projectId = props.projectId;
  const projects = organization?.projects;
  if (!projects) {
    return {};
  }

  if (!projectId) {
    navigate(routes.project(projects[Object.keys(projects)[0]].id));
    return {};
  }

  return {
    user: state.auth.user,
    project: organization?.projects?.[projectId],
    projectId: projectId,
    location: props.location,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setOrganizations: (orgs) => {
      dispatch(setOrganizations(orgs));
    },
    fetchOrganizations: () => {
      dispatch(fetchOrganizations());
    },
  };
};

const HomePage = connect(mapStateToProps, mapDispatchToProps)(Home);

export default HomePage;
