import React from "react";
import { connect } from "react-redux";
import SiteManager from "./SiteManager";
import {
  addProjectSite,
  removeProjectSite,
  setOrganizations,
  updateProject,
  updateProjectSite,
} from "../../../../core/redux/organizations/action";
import {
  fetchOrganizations,
  fetchSites,
} from "../../../../core/redux/organizations/thunk";
import store from "../../../../core/redux/store";

const mapStateToProps = (state, props) => {
  const organization = state.organizations[Object.keys(state.organizations)[0]];
  const project = organization.projects[props.projectId];
  return {
    user: state.auth.user,
    organization: organization,
    project: project,
    projectId: props.projectId,
    loctaion: props.location,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setOrganizations: (orgs) => {
      dispatch(setOrganizations(orgs));
    },
    fetchSites: (orgId, projectId) => {
      dispatch(fetchSites(orgId, projectId));
    },

    updateProjectSite: (orgId, projectId, siteId, patch) => {
      dispatch(updateProjectSite(orgId, projectId, siteId, patch));
    },
    removeProjectSite: (orgId, projectId, siteId) => {
      dispatch(removeProjectSite(orgId, projectId, siteId));
    },
  };
};

const SiteCreatorPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(SiteManager);

export default SiteCreatorPage;
