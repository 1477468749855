import React from "react";
import { Form } from "../../../components/Misc/Form";

export function OrgSettings({organization}) {
  return (
    <Form>
      <div className={"input-group"}>
        <label>Name</label>
        <input
          type={"text"}
          value={organization.name}
          name={"name"}
          autoComplete={"off"}
          disabled={true}
        />
      </div>
      <div className={"input-group"}>
        <label>Mitglieder</label>
        {Object.keys(organization.userRoles).map((userRoleKey, index) => {
          const user = organization.userRoles[userRoleKey].user;
          return <span key={"user-" + index}>{user.name}</span>;
        })}
      </div>
    </Form>
  );
}
