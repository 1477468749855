import React from "react";
import { StepGroup, StepsStyle } from "./styled";

export default function Steps({
  currentStep,
  steps,
  colorCompleted,
  colorUnCompleted,
}) {
  return (
    <StepsStyle>
      {steps.map((step, index) => {
        return (
          <StepGroup
            key={"step-bar-" + index}
            completed={index < currentStep}
            last={index < steps.length - 1}
            colorCompleted={colorCompleted}
            colorUnCompleted={colorUnCompleted}
          >
            <div className={"step-number"}>{index}</div>
            <span className={"step-title"}>{steps[index]}</span>
            <div className={"step-line"} />
          </StepGroup>
        );
      })}
    </StepsStyle>
  );
}
