import styled from "styled-components";
import { spacing } from "../../core/constants/spacing";
import fontSize from "../../core/constants/typeScale";
import { colors } from "../../core/constants/colors";

export const SimpleGrid = styled.div`
  margin-bottom: ${spacing.spaceMd};

  width: 100%;

  // display: grid;
  // grid-template-columns: repeat(auto-fit, 1fr);
  // grid-template-rows: 1fr;
  // grid-column-gap: ${spacing.spaceMd};
  // grid-row-gap: ${spacing.spaceMd};

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;

  .grid-link {
    flex: 1 1 128px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;

    height: ${spacing.space2xl};
    width: ${spacing.space2xl};
    padding: ${spacing.spaceSm} ${spacing.spaceMd};
    margin: ${spacing.space2xs};

    background-color: white;
    box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.1);
    text-decoration: none;
    font-size: ${fontSize.textLg};

    span {
      text-align: center;
      line-height: ${fontSize.textLg};
      color: black;
    }

    svg {
      height: 24px;
      width: 24px;

      color: #009e91;
      stroke: #009e91;
    }
  }

  //height: 100%;
  //width: 100%;

  h2 {
    margin-top: 0;
  }

  .grid-link-action {
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: ${spacing.spaceSm} ${spacing.space2xs};

    background-color: ${colors.greys["250"]};
    color: black;
    text-decoration: none;

    border-style: solid;
    border-width: 1px;
    border-radius: 6px;
    border-color: black;

    .action-symbol {
      font-size: ${fontSize.text4xl};
      margin-right: ${spacing.space2xs};
    }
  }
`;
