import React, { useEffect, useState } from "react";
import AppLayout from "../../../../components/Layout/AppLayout";
import { PageCreatorForm, SiteManagerStyle } from "./styled";
import { routes } from "../../../../core/constants/routes";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { authenticatedRequest } from "../../../../core/services/authenticatedRequest";
import store from "../../../../core/redux/store";
import { Button } from "../../../../components/Misc/Button";
import { navigate } from "gatsby";
import * as Sentry from "@sentry/gatsby";
import { AppPage } from "../../../../components/Misc/AppPage";
import SiteEditor from "./SiteEditor/SiteEditor";
import SiteList from "./SiteList";

export default function SiteManager({
  path,
  organization,
  project,
  location,
  updateProjectSite,
  removeProjectSite,
  projectId,
}) {
  const [name, setName] = useState("");
  const [selectedSiteId, setSelectedSiteId] = useState();

  function updateSite(siteId, patch) {
    authenticatedRequest({
      method: "put",
      url: routes.api.organizationProjectSite(
        organization.id,
        project.id,
        siteId
      ),
      data: patch,
    })
      .then((result) => {
        updateProjectSite(organization.id, project.id, siteId, result.data);
      })
      .catch((err) => {
        Sentry.captureException(err);
        console.error(err);
      });
  }

  function deleteSite(siteId) {
    if (
      !window.confirm(
        "Möchtest du diese Seite wirklich löschen? Damit geht die im Editor erstellte" +
          "Seite unwiderruflich verloren."
      )
    ) {
      return;
    }

    authenticatedRequest({
      method: "delete",
      url: routes.api.organizationProjectSite(
        organization.id,
        project.id,
        siteId
      ),
    })
      .then((result) => {
        removeProjectSite(organization.id, project.id, siteId);
      })
      .catch((err) => {
        Sentry.captureException(err);
        console.error(err);
      });
  }

  return (
    <AppLayout
      path={path}
      showNavigation={true}
      showHeader={true}
      location={location}
      title={"Seiten"}
      projectId={project.id}
      lastRoute={routes.projectWebsite(projectId)}
    >
      <AppPage>
        <SiteManagerStyle>
          <section>
            <SiteList selectSite={setSelectedSiteId} projectId={project.id} />
          </section>

          <section className={"site-editor-grid"}>
            <SiteEditor
              project={project}
              site={project.sites[selectedSiteId]}
              projectName={project.name}
              updateSite={updateSite}
              deleteSite={deleteSite}
              selectSite={setSelectedSiteId}
            />
          </section>
        </SiteManagerStyle>
      </AppPage>
    </AppLayout>
  );
}
