import React, { useEffect } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormLabel from "@mui/material/FormLabel";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import TextField from "@mui/material/TextField";
import { StyledInput } from "./styled";
import Button from "@mui/material/Button";
import { connect, getIn } from "formik";
import CookiePreview from "./CookiePreview";

function CookieConsentForm({ formik }) {
  const { handleSubmit, handleReset, values } = formik;

  return (
    <>
      <Grid container columnSpacing={2} rowSpacing={3}>
        <Grid item xs={12}>
          <Typography variant="h4">Cookie Banner Einstellungen</Typography>
        </Grid>

        <Grid item xs={6}>
          <FormControl fullWidth>
            <InputLabel id="position-label">Position</InputLabel>
            <Select
              labelId="position-label"
              id="position-select"
              value={formik.values.position}
              name={"position"}
              label="Position"
              onChange={formik.handleChange}
            >
              <MenuItem value={"bottom"}>Banner unten</MenuItem>
              <MenuItem value={"top"}>Banner oben</MenuItem>
              <MenuItem value={"top-static"}>Banner oben (pushdown)</MenuItem>
              <MenuItem value={"bottom-left"}>Schwebend links</MenuItem>
              <MenuItem value={"bottom-right"}>Schwebend rechts</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={6}>
          <FormControl fullWidth>
            <InputLabel id="position-label">Layout</InputLabel>
            <Select
              labelId="layout-label"
              id="layout-select"
              name={"theme"}
              value={formik.values.theme}
              label="Layout"
              onChange={formik.handleChange}
            >
              <MenuItem value={"block"}>Block</MenuItem>
              <MenuItem value={"classic"}>Classic</MenuItem>
              <MenuItem value={"edgeless"}>Edgeless</MenuItem>
              <MenuItem value={"wire"}>Wire</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={6}>
          <FormControl>
            <FormLabel id="complianceTypeLabel">Einwilligungs-Typ</FormLabel>
            <RadioGroup
              aria-labelledby="complianceTypeLabel"
              defaultValue="opt-in"
              name="type"
              value={formik.values.type}
              onChange={formik.handleChange}
            >
              <FormControlLabel
                value={"inform"}
                control={<Radio />}
                label="Besucher über Cookies informieren"
              />
              <FormControlLabel
                value={"opt-out"}
                control={<Radio />}
                label='Besucher können Cookies deaktivieren ("Opt-Out")'
              />
              <FormControlLabel
                value={"opt-in"}
                control={<Radio />}
                label='Besucher müssen einwilligen, bevor Inhalte von Drittparteien geladen werden ("Opt-In")'
              />
            </RadioGroup>
          </FormControl>
        </Grid>

        <Grid item xs={6}>
          <TextField
            label="Datenschutz Link"
            variant="standard"
            name={"content.href"}
            onChange={formik.handleChange}
            value={formik.values.content.href}
            fullWidth
          />
        </Grid>

        <Grid item xs={12} />

        <Grid container item xs={12} columnSpacing={2}>
          <Grid item xs={12}>
            <Typography variant="h6">Farben</Typography>
          </Grid>
          <Grid item xs={6}>
            <label htmlFor={"bannerColor"}>Banner Hintergrund</label>
            <StyledInput
              id={"bannerColor"}
              type={"color"}
              name={"colors.banner"}
              value={formik.values.colors.banner}
              onChange={formik.handleChange}
            />
          </Grid>
          <Grid item xs={6}>
            <label htmlFor={"bannerTextColor"}>Banner Schrift</label>

            <StyledInput
              id={"bannerTextColor"}
              type={"color"}
              name={"colors.bannerText"}
              value={formik.values.colors.bannerText}
              onChange={formik.handleChange}
            />
          </Grid>
          <Grid item xs={6}>
            <label htmlFor={"buttonColor"}>Button Hintergrund</label>

            <StyledInput
              id={"buttonColor"}
              type={"color"}
              name={"colors.button"}
              value={formik.values.colors.button}
              onChange={formik.handleChange}
            />
          </Grid>
          <Grid item xs={6}>
            <label htmlFor={"buttonTextColor"}>Button Schrift</label>

            <StyledInput
              id={"buttonTextColor"}
              type={"color"}
              name={"colors.buttonText"}
              value={formik.values.colors.buttonText}
              onChange={formik.handleChange}
            />
          </Grid>
        </Grid>

        <Grid item xs={12} />

        <Grid container item xs={12} spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h6">Texte</Typography>
          </Grid>

          <Grid item xs={12}>
            <TextField
              id="filled-multiline-flexible"
              label="Nachricht"
              multiline
              maxRows={4}
              // minRows={4}
              name={"content.message"}
              value={formik.values.content.message}
              onChange={formik.handleChange}
              variant="standard"
              fullWidth
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              id="filled-multiline-flexible"
              label="Ablehnen Button"
              multiline
              maxRows={4}
              name={"content.deny"}
              value={formik.values.content.deny}
              onChange={formik.handleChange}
              variant="standard"
              fullWidth
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              id="filled-multiline-flexible"
              label="Einwilligen Button"
              multiline
              maxRows={4}
              name={"content.allow"}
              value={formik.values.content.allow}
              onChange={formik.handleChange}
              variant="standard"
              fullWidth
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              id="filled-multiline-flexible"
              label="Kenntnissnahme"
              multiline
              maxRows={4}
              name={"content.dismiss"}
              value={formik.values.content.dismiss}
              onChange={formik.handleChange}
              variant="standard"
              fullWidth
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              id="filled-multiline-flexible"
              label="Datenschutz Link Button"
              multiline
              maxRows={4}
              name={"content.link"}
              value={formik.values.content.link}
              onChange={formik.handleChange}
              variant="standard"
              fullWidth
            />
          </Grid>
        </Grid>

        <Grid container item xs={12} spacing={2}>
          <Grid item xs={6}>
            <Button
              color="primary"
              variant="outlined"
              fullWidth
              type="button"
              onClick={formik.handleReset}
            >
              Abbrechen
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              color="primary"
              variant="contained"
              fullWidth
              type="submit"
              onClick={handleSubmit}
            >
              Speichern
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <CookiePreview settings={values} />
    </>
  );
}

export default connect(CookieConsentForm);
