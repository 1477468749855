import React from "react";
import { connect } from "react-redux";
import ProjectSettings from "./BuildComponent";
import { handleLogout } from "../../../core/services/auth";
import FooterBuilder from "./BuildComponent";
import { updateProject } from "../../../core/redux/organizations/action";
import store from "../../../core/redux/store";
import { fetchOrganizations } from "../../../core/redux/organizations/thunk";
import { patchUser } from "../../../core/redux/auth/action";
import BuildComponent from "./BuildComponent";
import { setAppState } from "../../../core/redux/appState/action";

const mapStateToProps = (state, props) => {
  const organization = state.organizations[Object.keys(state.organizations)[0]];
  if (!organization) return {};

  const project = organization.projects[props.projectId];

  return {
    user: state.auth.user,
    organization: organization,
    project: project,
    location: props.location,
    ...props,
  };
};

const mapDispatchToProps = (dispatch) => {
  const state = store.getState();
  return {
    updateProject: (orgId, projectId, patch) =>
      dispatch(updateProject(orgId, projectId, patch)),
    setAppState: (newState) => {
      dispatch(setAppState(newState));
    },
  };
};

const Build = connect(mapStateToProps, mapDispatchToProps)(BuildComponent);

export default Build;
