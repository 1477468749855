import styled from "styled-components";
import { spacing } from "../../core/constants/spacing";
import { colors } from "../../core/constants/colors";
import { Form } from "../../components/Misc/Form";
import { AppPage } from "../../components/Misc/AppPage";

export const PostsStyle = styled(AppPage)`
  table {
    th {
      padding-bottom: 0 !important;

      text-align: left;
    }

    td,
    th {
      padding: ${spacing.spaceXs};
    }

    tbody tr {
      cursor: pointer;

      &:hover,
      &:focus {
        background-color: ${colors.uranian["80"]};
      }
    }

    .future {
      background-color: hsl(100, 64%, 82%);
      //color: white;
    }

    .description {
      max-width: ${spacing.space7xl};

      overflow: hidden;
    }

    .recipients {
      text-align: center;
    }

    .churn {
      text-align: center;
    }
  }
`;
