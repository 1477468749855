import { Button } from "../../../components/Misc/Button";
import { Form } from "../../../components/Misc/Form";
import React, { useState } from "react";
import { authenticatedRequest } from "../../../core/services/authenticatedRequest";
import { routes } from "../../../core/constants/routes";
import * as Sentry from "@sentry/gatsby";

export function AccountSettings({ logout, user, setUser, setAppState }) {
  const [state, setState] = useState({
    name: user.name,
    email: user.email,
  });
  const [changePasswordState, setChangePasswordState] = useState({
    password: "",
    newPassword: "",
    confirmNewPassword: "",
  });
  const [showChangePassword, setShowChangePassword] = useState(false);

  function onChange(event) {
    setState((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  }

  function onPasswordStateChange(event) {
    setChangePasswordState((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  }

  function onUserUpdateFormSubmit(event) {
    authenticatedRequest({
      method: "patch",
      url: routes.api.user(user.id),
      data: state,
    })
      .then((result) => {
        setUser(result.data);
        setAppState({
          loading: false,
          error: false,
          statusMessage: "Änderungen gespeichert.",
        });
      })
      .catch((err) => {
        Sentry.captureException(err);
      });
  }

  function onPasswordChangeSubmit(event) {
    event.preventDefault();

    if (
      changePasswordState.newPassword !== changePasswordState.confirmNewPassword
    ) {
      setAppState({
        loading: false,
        error: true,
        statusMessage: "Die Passwörter stimmen nicht überein.",
      });
      return;
    }

    setAppState({
      loading: true,
      error: false,
    });

    authenticatedRequest({
      method: "patch",
      url: routes.api.user(user.id),
      data: {
        password: changePasswordState.newPassword,
      },
    })
      .then(() => {
        // does the token need to be updated?
        // TODO
        setAppState({
          loading: false,
          error: false,
          statusMessage: "Passwort geändert.",
        });
      })
      .catch((err) => {
        Sentry.captureException(err);
      });
  }

  return (
    <>
      {/* update user */}
      <Form onSubmit={onUserUpdateFormSubmit}>
        <div className={"input-group"}>
          <label htmlFor={"user-name"}>Name</label>
          <input
            id={"user-name"}
            type={"text"}
            value={state.name}
            onChange={onChange}
          />
        </div>

        <div className={"input-group"}>
          <label htmlFor={"user-email"}>E-Mail</label>
          <input
            id={"user-email"}
            type={"email"}
            value={state.email}
            onChange={onChange}
          />
        </div>

        <div className={"button-row"}>
          <Button className={"secondary"} onClick={logout}>
            Logout
          </Button>

          <Button
            className={"secondary"}
            type={"button"}
            onClick={() => setShowChangePassword(true)}
          >
            Passwort Ändern
          </Button>

          <Button className={"primary"} type={"submit"}>
            Speichern
          </Button>
        </div>
      </Form>

      <br />

      <Form
        onSubmit={onPasswordChangeSubmit}
        style={{
          borderStyle: "solid",
          display: showChangePassword ? "flex" : "none",
        }}
      >
        <div className={"input-group"}>
          <label htmlFor={"user-password"}>Aktuelles Passwort</label>
          <input
            id={"user-password"}
            type={"password"}
            name={"password"}
            value={changePasswordState.password}
            onChange={onPasswordStateChange}
          />
        </div>

        <div className={"input-group"}>
          <label htmlFor={"user-new-password"}>Neues Passwort</label>
          <input
            id={"user-new-password"}
            type={"password"}
            name={"newPassword"}
            value={changePasswordState.newPassword}
            onChange={onPasswordStateChange}
          />
        </div>

        <div className={"input-group"}>
          <label htmlFor={"user-confirm-new-password"}>
            Neues Passwort bestätigen
          </label>
          <input
            id={"user-confirm-new-password"}
            type={"password"}
            name={"confirmNewPassword"}
            value={changePasswordState.confirmNewPassword}
            onChange={onPasswordStateChange}
          />
        </div>

        <div className={"button-row"}>
          <Button className={"primary"} type={"submit"}>
            Speichern
          </Button>
        </div>
      </Form>
    </>
  );
}
