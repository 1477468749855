import React from "react";
import { Button } from "../../../../../../components/Misc/Button";
import { routes } from "../../../../../../core/constants/routes";
import styled from "styled-components";
import WhatsappComponent from "../../Dashboard/Whatsapp";
import { navigate } from "gatsby";

const StepTwoStyle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .actions {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
`;

export function StepOne({
  organization,
  project,
  orgName,
  projectId,
  setCurrentStep,
  updateProject,
}) {
  function onSubmit(event) {
    event.preventDefault();
  }

  // setCurrentStep(3);

  return (
    <StepTwoStyle>
      <p>
        Möchtest du einen WhatsApp Account mit {orgName} verknüpfen? Eingehende
        Nachrichten werden auf der Chat-Plattform im {orgName} Account angezeigt
        und sie können von dir und anderen Nutzern in {orgName} gelesen und
        beantwortet werden. Verknüpfe daher keinen privaten WhatsApp Account.
      </p>
      <p>
        Zum Verknüpfen musst du zuerst der "Multi-Geräte-Beta" beitreten. Öffne
        dazu Whatsapp, klicke auf die drei Punkte oben rechts und dann auf
        "Verknüpfte Geräte". Hier kannst du unter "Multi-Geräte-Beta" der Beta
        beitreten und dann mit "Gerät hinzufügen" diesen QR-Code einscannen.
      </p>
      <WhatsappComponent
        organization={organization}
        project={project}
        updateProject={updateProject}
      />
      <div className={"actions"}>
        <Button
          className={"secondary"}
          onClick={async () => {
            await navigate(routes.projectPlugins(projectId));
          }}
        >
          Abbrechen
        </Button>{" "}
        <Button
          className={"primary"}
          onClick={() => {
            setCurrentStep(2);
          }}
          disabled={project.whatsapp.jid.length > 0 ? "false" : "true"}
        >
          Weiter
        </Button>
      </div>
    </StepTwoStyle>
  );
}
