export const colors = {
  /* primary color */
  primary: {
    200: "hsl(215, 26%, 7%)",
    190: "hsl(215, 25%, 15%)",
    180: "hsl(215, 25%, 22%)",
    170: "hsl(215, 100%, 29%)",
    160: "hsl(215, 100%, 36%)",
    150: "hsl(215, 100%, 44%)",
    140: "hsl(215, 100%, 51%)",
    130: "hsl(215, 100%, 58%)",
    120: "hsl(215, 100%, 66%)",
    110: "hsl(215, 100%, 73%)",
    100: "hsl(215, 100%, 80%)", //#b699ff
    90: "hsl(215, 100%, 82%)",
    80: "hsl(215, 100%, 84%)",
    70: "hsl(215, 100%, 85%)",
    60: "hsl(215, 100%, 87%)",
    50: "hsl(215, 100%, 89%)",
    40: "hsl(215, 100%, 91%)",
    30: "hsl(215, 100%, 93%)",
    20: "hsl(215, 100%, 95%)",
    10: "hsl(215, 100%, 96%)",
    0: "hsl(215, 100%, 98%)",
  },
  // primary: {
  //   200: "hsl(257, 26%, 7%)",
  //   190: "hsl(257, 25%, 15%)",
  //   180: "hsl(257, 25%, 22%)",
  //   170: "hsl(257, 100%, 29%)",
  //   160: "hsl(257, 100%, 36%)",
  //   150: "hsl(257, 100%, 44%)",
  //   140: "hsl(257, 100%, 51%)",
  //   130: "hsl(257, 100%, 58%)",
  //   120: "hsl(257, 100%, 66%)",
  //   110: "hsl(257, 100%, 73%)",
  //   100: "hsl(257, 100%, 80%)", //#b699ff
  //   90: "hsl(257, 100%, 82%)",
  //   80: "hsl(257, 100%, 84%)",
  //   70: "hsl(257, 100%, 85%)",
  //   60: "hsl(257, 100%, 87%)",
  //   50: "hsl(257, 100%, 89%)",
  //   40: "hsl(257, 100%, 91%)",
  //   30: "hsl(257, 100%, 93%)",
  //   20: "hsl(257, 100%, 95%)",
  //   10: "hsl(257, 100%, 96%)",
  //   0: "hsl(257, 100%, 98%)",
  // },

  dashboard: {
    background: "hsl(355, 20%, 94%)",
  },

  // greys
  greys: {
    900: "hsl(0, 0%, 6%)",
    800: "hsl(0, 0%, 17%)",
    700: "hsl(0, 0%, 28%)",
    600: "hsl(0, 0%, 39%)",
    500: "hsl(0, 0%, 50%)",
    400: "hsl(0, 0%, 61%)",
    300: "hsl(0, 0%, 72%)",
    // used for input borders
    250: "hsl(0, 0%, 80%)",
    200: "hsl(0, 0%, 83%)",
    100: "hsl(0, 0%, 94%)",
    50: "hsl(0, 0%, 96%)",
  },

  status: {
    green: {
      light: "hsl(141, 34%, 82%)",
      dark: "hsl(141, 34%, 16%)",
    },
    red: {
      light: "hsl(355, 86%, 82%)",
      dark: "hsl(355, 86%, 20%)",
    },
  },

  // text
  text: {
    primary: "black",
    secondary: "hsl(0, 0%, 20%)",
    third: "hsl(0, 0%, 52%)",
  },

  // links
  links: {
    default: "hsl(218, 76%, 52%)",
    dark: "hsl(218, 76%, 42%)",
  },

  red: {
    background: "hsl(0, 40%, 84%)",
    primary: "hsl(0, 40%, 40%)",
    button: "#d11a2a",
    error: "#e65f59",
  },
  yellow: {
    background: "hsl(51, 82%, 75%)",
    primary: "hsl(51, 82%, 25%)",
  },
  green: {
    background: "hsl(85, 31%, 78%)",
    primary: "hsl(85, 31%, 25%)",
    success: "#0E9E57",
  },

  uranian: {
    0: "hsl(185, 100%, 96%)",
    10: "hsl(185, 100%, 93%)",
    80: "hsl(203, 100%, 88%)",
    100: "hsl(205, 100%, 86%)", //b8e1ff
    140: "hsl(211, 23%, 54%)",
    150: "hsl(212, 22%, 46%)",
    170: "hsl(216, 28%, 30%)",
    17025: "hsla(216, 28%, 30%, 25%)",
    190: "hsl(223, 49%, 14%)",
  },
};
