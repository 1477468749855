import React, { Component } from "react";
import axios from "axios";
import ContentBox from "@innovastudio/contentbox";
import "./contentbox.css";
import "./contentbuilder.css";
import "../../../../static/box/box-flex.css";
import "../../../../static/box/box.css";
import "../../../../static/assets/minimalist-blocks/content.css";
import "../../../../static/assets/ionicons/css/ionicons.min.css";
import "../../../../static/assets/scripts/glide/css/glide.core.min.css";
import "../../../../static/assets/scripts/glide/css/glide.theme.min.css";
import { addExternalScripts } from "../../../core/services/builderUtil";
import * as Sentry from "@sentry/gatsby";
import { routes } from "../../../core/constants/routes";

class BuilderControl extends Component {
  constructor(props) {
    super(props);

    this.saveContent = this.saveContent.bind(this);
    this.saveContentAndFinish = this.saveContentAndFinish.bind(this);
  }

  componentDidMount() {
    // Then init the ContentBox
    this.obj = new ContentBox({
      wrapper: ".is-wrapper",

      imageSelect: "/assets.html",
      fileSelect: "/assets.html",
      videoSelect: "/assets.html",

      slider: "glide",

      onUploadCoverImage: (e) => {
        this.uploadFile(e, (response) => {
          const uploadedImageUrl = response.data.url; // get saved image url
          this.obj.boxImage(uploadedImageUrl); // change cover image
        });
      },
      onMediaUpload: (e) => {
        this.uploadFile(e, (response) => {
          const uploadedImageUrl = response.data.url; // get saved file url
          this.obj.returnUrl(uploadedImageUrl);
        });
      },
      onVideoUpload: (e) => {
        this.uploadFile(e, (response) => {
          const uploadedFileUrl = response.data.url; // get saved file url
          this.obj.returnUrl(uploadedFileUrl);
        });
      },

      onChange: () => {
        this.props.applyLayout();

        //Auto Save
        clearTimeout(this.timeoutId);
        this.timeoutId = setTimeout(() => {
          this.saveContent();
        }, 1000);
      },

      zoom: 1.0,

      /* ContentBox settings */
      designUrl1: "/assets/designs/basic.js",
      designUrl2: "/assets/designs/examples.js",
      designPath: "/assets/designs/",
      contentStylePath: "/assets/styles/",

      /* ContentBuilder settings */
      modulePath: "/assets/modules/",
      fontAssetPath: "/assets/fonts/",
      assetPath: "/assets/",
      snippetUrl: "/assets/minimalist-blocks/content.js",
      snippetPath: "/assets/minimalist-blocks/",
      pluginPath: "/contentbuilder/",
      useLightbox: true,
      snippetCategories: [
        [120, "Basic"],
        [119, "Custom"],
        [118, "Article"],
        [101, "Headline"],
        [119, "Buttons"],
        [102, "Photos"],
        [103, "Profile"],
        [116, "Contact"],
        [104, "Products"],
        [105, "Features"],
        [106, "Process"],
        [107, "Pricing"],
        [108, "Skills"],
        [109, "Achievements"],
        [110, "Quotes!"],
        [111, "Partners"],
        [112, "As Featured On"],
        [113, "Page Not Found"],
        [114, "Coming Soon"],
        [115, "Help, FAQ"],
      ],
    });

    // Example of adding buttons on the sidebar
    this.obj.addButton({
      pos: 2, // button position
      title: "Undo", // title
      html: '<svg class="is-icon-flex" style="width:14px;height:14px;"><use xlink:href="#ion-ios-undo"></use></svg>', // icon
      onClick: () => {
        this.obj.undo();
      },
    });

    this.obj.addButton({
      pos: 3, // button position
      title: "Redo", // title
      html: '<svg class="is-icon-flex" style="width:14px;height:14px;"><use xlink:href="#ion-ios-redo"></use></svg>', // icon
      onClick: () => {
        this.obj.redo();
      },
    });

    this.obj.addButton({
      pos: 3, // button position
      title: "Bun", // title
      html: '<svg class="is-icon-flex" style="width:14px;height:14px;"><use xlink:href="#ion-ios-redo"></use></svg>', // icon
      onClick: () => {
        console.log(this.obj);
      },
    });

    let html, mainCss, sectionCss;

    if (this.props.initialHtml) {
      html = this.props.initialHtml;
      //
    } else {
      // Or load sample content on first start

      html = `
                    <div class="is-section is-section-100 is-shadow-1 is-bg-grey">
                        <div class="is-boxes">
                            <div class="is-box-img is-box is-box-6" style="background-color: rgb(247, 247, 247); background-image: linear-gradient(0deg, rgb(255, 208, 100), rgb(239, 98, 159));">
                                <div class="is-boxes ">
                                    <div class="is-overlay">
                                        <div class="is-overlay-bg" style="background-image: url(&quot;uploads/travel.png&quot;); background-position: 50% 60%; transform: translateY(-0.0631912px) scale(1.05);" data-bottom-top="transform:translateY(-120px) scale(1.05);" data-top-bottom="transform:translateY(120px) scale(1.05)"></div>
                                    </div>
                                </div>
                            </div>
                            <div class="is-box is-dark-text is-bg-light is-box-6">
                                <div class="is-boxes">
                                    <div class="is-box-centered">
                                        <div class="is-container container" style="max-width: 480px;">
                                            <div class="row clearfix">
                                                <div class="column full right">
                                                    <div class="display">
                                                        <h1 class="size-72">A Little Story</h1>
                                                    </div>
                                                    <p style="border-bottom: 3px solid #333; width: 80px; display: inline-block;"></p>
                                                </div>
                                            </div>
                                            <div class="row clearfix">
                                                <div class="column full">
                                                    <div class="spacer height-40"></div>
                                                </div>
                                            </div>
                                            <div class="row clearfix">
                                                <div class="column full">
                                                    <p style="text-align: justify;">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    `;
      mainCss = "";
      sectionCss = "";
    }

    this.obj.loadStyles(mainCss, sectionCss);
    this.obj.loadHtml(html);

    // Add required scripts for viewing the content
    addExternalScripts(
      "/box/box-flex.js, /assets/scripts/glide/glide.js, /assets/minimalist-blocks/content.js"
    );

    // https://stackoverflow.com/questions/37949981/call-child-method-from-parent
    if (this.props.doSave) this.props.doSave(this.saveContent); // Make it available to be called using doSave
    if (this.props.doSaveAndFinish)
      this.props.doSaveAndFinish(this.saveContentAndFinish);
  }

  loadLanguageFile = (languageFile, callback) => {
    if (!this.isScriptAlreadyIncluded(languageFile)) {
      const script = document.createElement("script");
      script.src = languageFile;
      script.async = true;
      script.onload = () => {
        if (callback) callback();
      };
      document.body.appendChild(script);
    } else {
      if (callback) callback();
    }
  };

  isScriptAlreadyIncluded = (src) => {
    const scripts = document.getElementsByTagName("script");
    for (let i = 0; i < scripts.length; i++)
      if (scripts[i].getAttribute("src") === src) return true;
    return false;
  };

  uploadFile = (e, callback) => {
    const selectedFile = e.target.files[0];
    const filename = selectedFile.name;
    const reader = new FileReader();
    reader.onload = (e) => {
      let base64 = e.target.result;
      base64 = base64.replace(/^data:(.*?);base64,/, "");
      base64 = base64.replace(/ /g, "+");

      // Upload process
      axios
        .post(
          routes.api.organizationProjectSaveImages(
            this.props.organization.id,
            this.props.project.id
          ),
          { image: base64, filename: filename }
        )
        .then((response) => {
          callback(response);
        })
        .catch((err) => {
          console.log(err);
        });
    };
    reader.readAsDataURL(selectedFile);
  };

  save = (callback) => {
    if (this.props.base64Handler !== "") {
      // If base64Handler is specified

      // Save all embedded base64 images first
      this.obj.saveImages(
        "",
        () => {
          // Then save the content
          let html = this.obj.html();

          if (callback) callback(html);
        },
        (img, base64, filename) => {
          // Upload image process
          axios
            .post(this.props.base64Handler, {
              image: base64,
              filename: filename,
            })
            .then((response) => {
              const uploadedImageUrl = response.data.url; // get saved image url

              img.setAttribute("src", uploadedImageUrl); // set image src
            })
            .catch((err) => {
              console.log(err);
              Sentry.captureException(err);
            });
        }
      );
    } else {
      let html = this.obj.html();

      if (callback) callback(html);
    }
  };

  saveContent = () => {
    this.save((html, mainCss, sectionCss) => {
      this.props.onSave(html, mainCss, sectionCss);
    });
  };

  saveContentAndFinish = () => {
    this.save((html, mainCss, sectionCss) => {
      this.props.onSaveAndFinish(html, mainCss, sectionCss);
    });
  };

  render() {
    return <div className="is-wrapper"></div>;
  }

  componentWillUnmount() {
    this.obj.destroy();
  }
}

export default BuilderControl;
