import styled from "styled-components";
import { spacing } from "../../../core/constants/spacing";
import { colors } from "../../../core/constants/colors";
import borderRadius from "../../../core/constants/borderRadius";
import fontSize from "../../../core/constants/typeScale";

export const StyledIframeWrapper = styled.div`
  height: 300px;

  iframe {
    width: 200%;
    height: 600px;
    min-height: ${spacing.space8xl};

    -ms-zoom: 0.5;
    -moz-transform: scale(0.5);
    -moz-transform-origin: 0 0;
    -o-transform: scale(0.5);
    -o-transform-origin: 0 0;
    -webkit-transform: scale(0.5);
    -webkit-transform-origin: 0 0;
  }
`;

export const QrCode = styled.div`
  display: ${(props) => props.display};
  flex-direction: column;
  justify-content: center;
  align-content: center;

  margin-top: ${spacing.spaceMd};

  h2 {
    text-align: center;
  }

  img {
    margin: 0 auto;
    width: min-content;
    height: min-content;
  }

  .download-link-button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: ${spacing.space2xs};
    width: 120px;
    margin: 0 auto;

    background-color: white;
    color: ${colors.primary["170"]};
    border-style: solid;
    border-width: 1px;
    //border-radius: ${borderRadius.small};
    font-size: ${fontSize.textMd};
    //letter-spacing: 0.12em;
    cursor: pointer;
    text-decoration: none;

    color: ${colors.primary["170"]};

    svg {
      margin-right: ${spacing.space2xs};
    }
  }
`;
