import React, { useState } from "react";
import { StepTwo } from "./StepTwo";
import { StepOne } from "./StepOne";

export function StepContent({
  currentStep,
  setCurrentStep,
  orgId,
  projectId,
  orgName,
  organization,
  project,
  updateProject,
}) {
  if (currentStep === 1) {
    return (
      <StepOne
        orgId={orgId}
        projectId={projectId}
        setCurrentStep={setCurrentStep}
        orgName={orgName}
        organization={organization}
        project={project}
        updateProject={updateProject}
      />
    );
  }
  if (currentStep === 2) {
    return (
      <StepTwo
        orgId={orgId}
        projectId={projectId}
        setCurrentStep={setCurrentStep}
      />
    );
  }
}
