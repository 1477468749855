import { createGlobalStyle } from "styled-components";
import "@fontsource/montserrat";
import fontSize from "../../core/constants/typeScale";
import { colors } from "../../core/constants/colors";

const GlobalStyle = createGlobalStyle`

  
  // body {
  //   height: 100%;
  //   margin: 0;
  //   padding: 0;
  //   font-family: "Montserrat", Helvetica, sans-serif;
  // }
  //
  // button, label, textarea, input {
  //   font-family: "Montserrat", Helvetica, sans-serif;
  //   font-size: ${fontSize.textMd};
  // }
  
  body {
    // background-color: ${colors.dashboard.background};
  }

`;

export default GlobalStyle;
