import React, { useEffect } from "react";
import AppLayout from "../../components/Layout/AppLayout";
import { routes } from "../../core/constants/routes";
import { PostsStyle } from "./styled";
import lodash from "lodash";
import { navigate } from "gatsby";
import store from "../../core/redux/store";
import { fetchPosts } from "../../core/redux/organizations/thunk";

export default function Posts({
  project,
  projectId,
  organization,
  location,
  setAppState,
}) {
  // fetch posts to replace old state
  useEffect(() => {
    store.dispatch(fetchPosts(organization.id, projectId));
  }, []);



  // show loading bar if no posts at all are in the state
  useEffect(() => {
    if (!project.posts) {
      setAppState({
        loading: true,
      });
    } else {
      setAppState({
        loading: false,
      });
    }
  }, [project.posts]);

  return

  // Skeletion
  if (!project.posts) {
    return (
      <AppLayout
        title={"Posts"}
        subtitle={project.name}
        showHeader={true}
        showNavigation={true}
        lastRoute={routes.project(project.id)}
        location={location}
        projectId={project.id}
      >
        <PostsStyle>
          <table>
            <thead>
              <tr>
                <th className={"title"}>Titel</th>
                <th className={"date"}>Datum</th>
                <th className={"description"}>Beschreibung</th>
                <th className={"recipients"}>Empfänger</th>
                <th className={"recipients"}>Klicks auf Benachrichtigung</th>
                <th className={"churn"}>Churn</th>
              </tr>
            </thead>
            <tbody></tbody>
          </table>
        </PostsStyle>
      </AppLayout>
    );
  }

  return (
    <AppLayout
      title={"Posts"}
      subtitle={project.name}
      showHeader={true}
      showNavigation={true}
      lastRoute={routes.project(project.id)}
      location={location}
      projectId={project.id}
    >
      <PostsStyle>
        <table>
          <thead>
            <tr>
              <th className={"title"}>Titel</th>
              <th className={"date"}>Versand am</th>
              <th className={"description"}>Beschreibung</th>
              <th className={"recipients"}>Empfänger</th>
              <th className={"recipients"}>Klicks auf Benachrichtigung</th>
              <th className={"churn"}>Churn</th>
            </tr>
          </thead>
          <tbody>
            {Object.keys(project.posts)
              .reverse()
              .map((key, index) => {
                const post = project.posts[key];
                const postDate = new Date(post.postDate);
                return (
                  <tr
                    onClick={() => {
                      console.log(post);
                      navigate(routes.projectPostEditor(project.id, post.id));
                    }}
                    // className={postDate > new Date(Date.now()) ? "future" : ""}
                  >
                    <td className={"title"}>{post.title}</td>
                    <td
                      className={
                        postDate > new Date(Date.now()) ? "date future" : "date"
                      }
                    >
                      {postDate.toLocaleDateString()}
                    </td>
                    <td className={"description"}>
                      {lodash.truncate(post.description)}
                    </td>
                    <td className={"recipients"}>
                      {post.stats?.sendCount || 0}
                    </td>
                    <td className={"recipients"}>{post.stats?.clicks || 0}</td>
                    <td className={"churn"}>{post.stats?.deleteCount || 0}</td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </PostsStyle>
    </AppLayout>
  );
}
