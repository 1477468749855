import React, { useEffect, useState } from "react";
import { Link, navigate } from "gatsby";
import { Fixed, HeaderWrapper, StateMessage } from "./styled";
import { ArrowLeft } from "../../../assets/icons/ArrowLeft";
import Select from "react-select";
import { handleLogout } from "../../../core/services/auth";
import { connect } from "react-redux";
import { routes } from "../../../core/constants/routes";
import BarLoader from "react-spinners/BarLoader";
import { AlertCircle } from "../../../assets/icons/AlertCircle";
import { Checkmark } from "../../../assets/icons/Checkmark";
import { setAppState } from "../../../core/redux/appState/action";

const mapStateToProps = (state, props) => {
  const organization = state.organizations[Object.keys(state.organizations)[0]];

  return {
    user: state.auth.user,
    organization: organization,
    projects: organization.projects,
    projectId: props.projectId,
    loading: state.appState.loading,
    statusMessage: state.appState.statusMessage,
    error: state.appState.error,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => {
      handleLogout();
    },
    setAppState: (newState) => {
      dispatch(setAppState(newState));
    },
  };
};

function projectToOptions(projects) {
  let arr = [];
  Object.keys(projects).map((key, index) => {
    const project = projects[key];
    arr.push({
      value: project.id,
      label: project.name,
    });
  });
  return arr;
}

function HeaderComponent({
  title,
  subtitle,
  lastRoute,
  showNavigation,
  showHeader,
  projects,
  projectId,
  pageWidth,
  loading = false,
  statusMessage,
  error = false,
  setAppState,
}) {
  const options = projectToOptions(projects);

  function onProjectChange(event) {
    navigate(routes.project(event.value));
  }

  useEffect(() => {
    setTimeout(() => {
      setAppState({
        statusMessage: "",
        error: false,
      });
    }, 8000);
  }, [statusMessage]);

  return (
    <HeaderWrapper show={showHeader}>
      <Fixed showNavigation={showNavigation} pageWidth={pageWidth}>
        {showNavigation ? (
          <button
            onClick={() => (lastRoute ? navigate(lastRoute) : navigate(-1))}
            className={"box arrow-left"}
          >
            <ArrowLeft stroke={"black"} />
          </button>
        ) : (
          <div className={"box"} />
        )}

        <div className={"box meta-title"}>
          <h4>{title}</h4>
        </div>

        <div className={"box"}>
          <Select
            options={options}
            isDisabled={Object.keys(projects).length <= 1}
            defaultValue={
              projectId
                ? options.find((element) => element.value === projectId)
                : options[0]
            }
            className={"project-select"}
            onChange={(e) => onProjectChange(e)}
          />
        </div>
      </Fixed>
      <div className={"loading-bar"}>
        <BarLoader color={"blue"} loading={loading} size={150} width={"100%"} />
      </div>
      <StateMessage error={error} show={!!statusMessage}>
        <span className={"icon"}>
          {error ? (
            <AlertCircle stroke={"white"} />
          ) : (
            <Checkmark stroke={"white"} />
          )}
        </span>
        {statusMessage}
      </StateMessage>
    </HeaderWrapper>
  );
}

const Header = connect(mapStateToProps, mapDispatchToProps)(HeaderComponent);

export default Header;
