import React, { useState } from "react";
import { AppPage } from "../../../../components/Misc/AppPage";
import { routes } from "../../../../core/constants/routes";
import AppLayout from "../../../../components/Layout/AppLayout";
import Steps from "../../../../components/Misc/Steps/Steps";
import { colors } from "../../../../core/constants/colors";
import { StepContent } from "./Steps";

export default function CustomDomain({ organization, project, location }) {
  const [currentStep, setCurrentStep] = useState(1);
  const steps = ["Schritt 1", "Schritt 2"];

  return (
    <AppLayout
      showHeader={true}
      title={"Custom Domain"}
      subtitle={project.name}
      showNavigation={true}
      lastRoute={routes.projectPlugins(project.id)}
      location={location}
      projectId={project.id}
    >
      <AppPage>
        <Steps
          currentStep={currentStep}
          steps={steps}
          colorCompleted={colors.primary["130"]}
          colorUnCompleted={colors.greys["700"]}
        />
        <br />

        <StepContent
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          projectId={project.id}
          orgId={organization.id}
          orgName={organization.name}
          organization={organization}
          project={project}
          // updateProject={updateProject}
        />
      </AppPage>
    </AppLayout>
  );
}
