import styled from "styled-components";
import { spacing } from "../../../core/constants/spacing";
import { colors } from "../../../core/constants/colors";
import fontSize from "../../../core/constants/typeScale";
import dimensions from "../../../core/constants/dimensions";
import breakpoints from "../../../core/constants/breakpoints";

export const HeaderWrapper = styled.section`
  height: ${dimensions.headerHeight};
  max-width: 100%;

  display: ${(props) => (props.show ? "flex" : "none")};
  align-items: center;
  justify-content: center;

  .loading-bar,
  .message-bar {
    width: 100vw;
    max-width: ${(props) =>
      props.pageWidth ? props.pageWidth : dimensions.desktopWidth};
    position: fixed;
    top: 0;
    z-index: 3;
    padding: 0 ${spacing.spaceSm};

    display: flex;
  }

  .message-bar {
    top: ${dimensions.headerHeight};
  }
`;

export const Fixed = styled.div`
  width: 100vw;
  max-width: ${(props) =>
    props.pageWidth ? props.pageWidth : dimensions.desktopWidth};
  height: ${dimensions.headerHeight};
  position: fixed;
  top: 0;
  z-index: 3;
  padding: 0 ${spacing.spaceSm};

  display: flex;

  // display: grid;
  // grid-template-columns: {(props) =>
  //   props.showNavigation
  //     ? "50px minmax(auto, 80%) 200px"
  //     : "50px minmax(auto, 80%) 200px"};
  // grid-template-rows: 1fr;
  // grid-column-gap: 0px;
  // grid-row-gap: 0px;

  //flex-direction: row;
  //align-items: center;
  //justify-content: center;

  border-style: none none solid none;
  border-width: 1px;
  border-color: ${colors.greys["200"]};
  box-sizing: border-box;

  background-color: white;
  //background-color: ${colors.primary["170"]};

  @media (min-width: ${dimensions.desktopWidth}) {
    padding: 0;
  }

  .box {
    flex: 1;
    display: flex;
    justify-content: center;

    padding: 0 8px;
  }

  .arrow-left {
    // grid-area: 1 / 1 / 2 / 2;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0;

    text-decoration: none;
    cursor: pointer;

    background-color: transparent;
    border-style: none;
  }

  .meta-title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    //color: white;
    color: ${colors.text.primary};

    h4 {
      font-size: ${fontSize.textLg};
      font-weight: 400;
    }
  }

  .project-select {
    margin: auto;
    //min-width: 200px;
    width: 100%;
    max-width: 300px;

    div {
      border-radius: 0px !important;
    }
  }
`;

export const StateMessage = styled.div`
  position: fixed;
  top: ${dimensions.headerHeight};
  z-index: 3;

  display: ${(props) => (props.show ? "flex" : "none")};
  justify-content: center;
  align-items: center;

  width: 100vw;
  max-width: ${(props) =>
    props.pageWidth ? props.pageWidth : dimensions.desktopWidth};

  padding: ${spacing.space2xs};

  background-color: ${(props) =>
    props.error ? colors.red.error : colors.green.success};
  color: white;

  .icon {
    margin-right: ${spacing.spaceXs};
  }
`;
