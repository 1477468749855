import React from "react";
import { connect } from "react-redux";
import PostEditor from "./PostEditor";
import { setAppState } from "../../../core/redux/appState/action";
import {
  updateProject,
  updateProjectPost,
} from "../../../core/redux/organizations/action";

const mapStateToProps = (state, props) => {
  const organization = state.organizations[Object.keys(state.organizations)[0]];
  if (!organization) return {};
  const projectId = props.projectId;
  const postId = props.postId;
  const project = organization.projects[projectId];
  console.log(props);
  return {
    user: state.auth.user,
    project: project,
    projectId: projectId,
    organization: organization,
    post: project.posts[postId],
    postId: postId,
    ...props,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setAppState: (newState) => {
      dispatch(setAppState(newState));
    },
    updatePost: (orgId, projectId, postId, patch) => {
      dispatch(updateProjectPost(orgId, projectId, postId, patch));
    },
  };
};

const PostEditorPage = connect(mapStateToProps, mapDispatchToProps)(PostEditor);

export default PostEditorPage;
