import { routes } from "../../constants/routes";
import { setOrganizations, updateProject } from "./action";
import { authenticatedRequest } from "../../services/authenticatedRequest";
import { setAppState, setFetching } from "../appState/action";
import { arrayToObject } from "../../services/arrayToObject";
import * as Sentry from "@sentry/gatsby";

export function fetchOrganizations() {
  return function (dispatch, getState) {
    if (!getState().appState.fetching) {
      dispatch(setFetching(true));
      return authenticatedRequest({
        method: "get",
        url: routes.api.organization(getState().auth.user.organizations[0]),
      })
        .then((result) => {
          let projects = result.data.projects.map((project) => {
            return {
              ...project,
              sites: arrayToObject(project.sites),
            };
          });
          const organizations = {
            [result.data.id]: {
              ...result.data,
              projects: arrayToObject(projects),
            },
          };

          dispatch(setOrganizations(organizations));
          dispatch(setFetching(false));
        })
        .catch((err) => {
          console.error(err);
          Sentry.captureException(err);
          dispatch(setFetching(false));
        });
    }
  };
}

export function fetchSites(orgId, projectId) {
  return function (dispatch, getState) {
    return authenticatedRequest({
      method: "get",
      url: routes.api.organizationProjectSites(orgId, projectId),
    })
      .then((result) => {
        dispatch(
          updateProject(orgId, projectId, {
            sites: arrayToObject(result.data),
          })
        );
      })
      .catch((err) => {
        console.error(err);
        Sentry.captureException(err);
      });
  };
}

export function fetchPosts(orgId, projectId) {
  return function (dispatch, getState) {
    return authenticatedRequest({
      method: "get",
      url: routes.api.organizationProjectPosts(orgId, projectId),
    })
      .then((result) => {
        dispatch(
          updateProject(orgId, projectId, {
            posts: arrayToObject(result.data),
          })
        );
      })
      .catch((err) => {
        console.error(err);
        Sentry.captureException(err);
      });
  };
}
