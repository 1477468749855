import React, { useEffect, useState } from "react";
import { routes } from "../../../../core/constants/routes";
import AppLayout from "../../../../components/Layout/AppLayout";
import { AppPage } from "../../../../components/Misc/AppPage";
import axios from "axios";
import { QrCode } from "../../../Website/Build/styled";
import Collapse, { Panel } from "rc-collapse";
import { DownloadIcon } from "../../../../assets/icons/DownloadIcon";
import { useFormik } from "formik";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { StyledInput } from "./styled";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import { navigate } from "gatsby";
import {
  QueryClientProvider,
  useQueryClient,
  useQuery,
  useMutation,
} from "react-query";
import { authenticatedRequest } from "../../../../core/services/authenticatedRequest";
import CircularProgress from "@mui/material/CircularProgress";
import { Formik } from "formik";
import CookieConsentForm from "./CookieConsentForm";
import CookiePreview from "./CookiePreview";
import { Helmet } from "react-helmet";

export default function CookieConsentComponent({
  organization,
  project,
  location,
}) {
  // Get QueryClient from the context
  const queryClient = useQueryClient();

  const { isLoading, error, data, isFetching } = useQuery("getSettings", () =>
    authenticatedRequest({
      method: "get",
      url: routes.api.organizationProjectSettings(organization.id, project.id),
    })
  );

  const updateSettingsMutation = useMutation((data) => {
    console.log("running mutation");
    console.log(data);
    return authenticatedRequest({
      method: "post",
      url: routes.api.organizationProjectSettings(organization.id, project.id),
      data: {
        cookiePlugin: data,
      },
    });
  });

  const saveSettings = async (data) => {
    updateSettingsMutation.mutate(data, {
      onSuccess: () => {
        queryClient.invalidateQueries("getSettings");
        navigate(routes.projectPlugins(project.id));
      },
      onError: (err) => {
        console.log(err);
      },
    });
  };

  useEffect(() => {
    console.log(data);
  }, [data]);

  if (isLoading) {
    return (
      <AppLayout
        showHeader={true}
        title={"Cookie Banner Einstellungen"}
        subtitle={project.name}
        showNavigation={true}
        lastRoute={routes.projectPlugins(project.id)}
        location={location}
        projectId={project.id}
      >
        <AppPage>
          <Box sx={{ display: "flex" }}>
            <CircularProgress />
          </Box>
        </AppPage>
      </AppLayout>
    );
  }

  return (
    <AppLayout
      showHeader={true}
      title={"Cookie Banner Einstellungen"}
      subtitle={project.name}
      showNavigation={true}
      lastRoute={routes.projectPlugins(project.id)}
      location={location}
      projectId={project.id}
    >
      <AppPage>
        <Helmet>
          <link
            rel="stylesheet"
            type="text/css"
            href="https://cdn.jsdelivr.net/npm/cookieconsent@3/build/cookieconsent.min.css"
          />
          <script
            src="https://cdn.jsdelivr.net/npm/cookieconsent@3/build/cookieconsent.min.js"
            data-cfasync="false"
          />
        </Helmet>
        <Formik
          initialValues={data?.data.cookiePlugin}
          onSubmit={(data) => {
            console.log("trying to submit...");
            console.log(data);
            saveSettings(data);
          }}
          validate={() => {
            console.log("validate");
            return 1;
          }}
        >
          <CookieConsentForm />
        </Formik>

      </AppPage>
    </AppLayout>
  );
}
