import React from "react";
import { connect } from "react-redux";
import Settings from "./Settings";
import { handleLogout } from "../../core/services/auth";
import { updateProject } from "../../core/redux/organizations/action";
import { setAppState } from "../../core/redux/appState/action";
import { setUser } from "../../core/redux/auth/action";

const mapStateToProps = (state, props) => {
  const organization = state.organizations[Object.keys(state.organizations)[0]];
  if (!organization) return {};
  return {
    user: state.auth.user,
    organization: organization,
    location: props.location,
    project: organization.projects[props.projectId],
    loading: state.appState.loading,
    error: state.appState.error,
    statusMessage: state.appState.statusMessage,
    ...props,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => {
      handleLogout();
    },
    updateProject: (orgId, projectId, patch) => {
      dispatch(updateProject(orgId, projectId, patch));
    },
    setAppState: (newState) => {
      dispatch(setAppState(newState));
    },
    setUser: (user) => {
      dispatch(setUser(user));
    },
  };
};

const SettingsPage = connect(mapStateToProps, mapDispatchToProps)(Settings);

export default SettingsPage;
