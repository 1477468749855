import React from "react";
import Layout from "../components/Layout/Layout";
import { Router } from "@reach/router";
import Login from "../components/Auth/Login";
import PrivateRoute from "../components/Auth/privateRoute";
import { routes } from "../core/constants/routes";
import HomePage from "../containers/Home";
import SitesPage from "../containers/Website/Sites";
import SiteCreatorPage from "../containers/Website/Sites/SiteManager";
import PushPage from "../containers/Push";
import MenuBuilderPage from "../containers/Website/MenuBuilder";
import FooterBuilderPage from "../containers/Website/FooterBuilder";
import ProjectMenuPage from "../containers/Website/ProjectMenu";
import SiteBuilderPage from "../containers/Website/Sites/SiteBuilder";
import Build from "../containers/Website/Build";
import PostsPage from "../containers/Posts";
import PostEditorPage from "../containers/Posts/PostEditor";
import CodeGenerator from "../containers/Plugins/containers/CodeGenerator";
import ChatwootSetupPage from "../containers/Plugins/containers/Chatwoot/Setup";
import ChatwootDashboardPage from "../containers/Plugins/containers/Chatwoot/Dashboard";
import SettingsPage from "../containers/Settings";
import PluginsPage from "../containers/Plugins";
import CustomDomainPage from "../containers/Plugins/containers/CustomDomain";
import CookieConsentPage from "../containers/Plugins/containers/CookieConsent";

export default function IndexPage() {
  return (
    <Layout>
      <Router>
        <Login path="/login" />

        {/*Home*/}
        <PrivateRoute path={"/"} component={HomePage} />
        <PrivateRoute path={routes.projectRouter} component={HomePage} />

        {/*Push*/}
        <PrivateRoute path={routes.projectPushRouter} component={PushPage} />

        {/*Chat*/}

        {/*Website*/}
        <PrivateRoute
          path={routes.projectWebsiteRouter}
          component={ProjectMenuPage}
        />
        <PrivateRoute
          path={routes.projectMenuBuilderRouter}
          component={MenuBuilderPage}
        />
        <PrivateRoute
          path={routes.projectFooterBuilderRouter}
          component={FooterBuilderPage}
        />
        <PrivateRoute path={routes.projectSitesRouter} component={SitesPage} />
        <PrivateRoute
          path={routes.projectSiteManagerRouter}
          component={SiteCreatorPage}
        />
        <PrivateRoute
          path={routes.projectSiteBuilderRouter}
          component={SiteBuilderPage}
        />
        <PrivateRoute path={routes.projectBuildRouter} component={Build} />

        {/*Plugins*/}
        <PrivateRoute
          path={routes.projectPluginsRouter}
          component={PluginsPage}
        />
        <PrivateRoute
          path={routes.projectCodeGeneratorRouter}
          component={CodeGenerator}
        />
        <PrivateRoute
          path={routes.projectChatwootDashboardRouter}
          component={ChatwootDashboardPage}
        />
        <PrivateRoute
          path={routes.projectChatwootSetupRouter}
          component={ChatwootSetupPage}
        />
        <PrivateRoute
          path={routes.projectCustomDomainRouter}
          component={CustomDomainPage}
        />
        <PrivateRoute
          path={routes.projectPluginsCookieConsentRouter}
          component={CookieConsentPage}
        />

        {/*Posts*/}
        <PrivateRoute path={routes.projectPostsRouter} component={PostsPage} />
        <PrivateRoute
          path={routes.projectPostEditorRouter}
          component={PostEditorPage}
        />

        {/*Settings*/}
        <PrivateRoute path={routes.settingsRouter} component={SettingsPage} />
      </Router>
    </Layout>
  );
}
