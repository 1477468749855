import React from "react";
import { connect } from "react-redux";
import Push from "./Push";
import { setAppState } from "../../core/redux/appState/action";
import { fetchOrganizations } from "../../core/redux/organizations/thunk";
import { organizations } from "../../core/redux/organizations/reducer";
import { updateProject } from "../../core/redux/organizations/action";

const mapStateToProps = (state, props) => {
  const organization = state.organizations[Object.keys(state.organizations)[0]];
  if (!organization) return {};
  const projectId = props.projectId;
  const project = organization.projects[projectId];
  return {
    user: state.auth.user,
    project: project,
    organization: organization,
    ...props,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setAppState: (newState) => {
      dispatch(setAppState(newState));
    },
    fetchOrganizations: () => {
      dispatch(fetchOrganizations());
    },
    updateProject: (organizationId, projectId, patch) => {
      dispatch(updateProject(organizationId, projectId, patch));
    },
  };
};

const PushPage = connect(mapStateToProps, mapDispatchToProps)(Push);

export default PushPage;
