import React, { useEffect, useRef } from "react";
import { Link } from "gatsby";
import { routes } from "../../core/constants/routes";
import { WarningIcon } from "./assets/icons/WarningIcon";
import { navigate } from "gatsby";

export default function SignInForm(
  { text, requestLogin, message, state, setState, handleAuthError },
  cursor = "pointer"
) {
  function handleInputChange(event) {
    event.persist();
    setState((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  }

  function onSubmit(event) {
    event.preventDefault();

    requestLogin({
      email: state.email,
      password: state.password,
      handleAuthError: handleAuthError,
    }).then((result) => {
      navigate(routes.dashboard);
    });
  }

  return (
    <form className={"auth-box-form"} onSubmit={onSubmit}>
      <div className={"row"}>
        <label htmlFor={"emailInput"}>{text.email}</label>
        <input
          id={"emailInput"}
          type={"email"}
          name={"email"}
          value={state.email}
          onChange={handleInputChange}
          required={true}
          autoFocus
        />
      </div>

      <div className={"row"}>
        <div className={"labelRow"}>
          <label htmlFor={"passwordInput"}>{text.password}</label>
          {/*<Link className={"passwordResetLink"} to={routes.resetPassword} type={"button"}>*/}
          {/*  {text.forgotPassword}*/}
          {/*</Link>*/}
        </div>
        <input
          id={"passwordInput"}
          type={"password"}
          name={"password"}
          value={state.password}
          onChange={handleInputChange}
          required={true}
        />
      </div>

      <div className={"message"} style={{ display: message ? "flex" : "none" }}>
        <WarningIcon stroke={"#e85d75"} />
        {message}
      </div>

      <div className={"row"}>
        <button className={"signInButton"} type={"submit"}>
          {text.buttonText}
        </button>
      </div>
    </form>
  );
}
