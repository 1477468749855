import React from "react";
import AppLayout from "../../../components/Layout/AppLayout";
import { SimpleGrid } from "../../../components/Misc/SimpleGrid";
import { Link } from "gatsby";
import { routes } from "../../../core/constants/routes";
import { Styles } from "./styled";
import { SettingsIcon } from "../../../assets/icons/SettingsIcon";
import { MenuIcon } from "../../../assets/icons/MenuIcon";
import { FileIcon } from "../../../assets/icons/FileIcon";
import { FooterIcon } from "../../../assets/icons/FooterIcon";
import { AppPage } from "../../../components/Misc/AppPage";
import { fetchOrganizations } from "../../../core/redux/organizations/thunk";
import store from "../../../core/redux/store";
import { TerminalIcon } from "../../../assets/icons/TerminalIcon";

export default function ProjectMenu({
  path,
  organization,
  projectId,
  location,
  project,
}) {
  if (!project) {
    store.dispatch(fetchOrganizations());
    return "loading";
  }

  return (
    <AppLayout
      path={path}
      showHeader={true}
      title={"Website"}
      subtitle={project?.name}
      showNavigation={true}
      lastRoute={routes.project(projectId)}
      location={location}
      projectId={project?.id}
    >
      <AppPage>
        <SimpleGrid>
          <Link
            className={"grid-link"}
            to={routes.projectMenuBuilder(projectId)}
          >
            <MenuIcon />
            <span>Menü</span>
          </Link>

          <Link className={"grid-link"} to={routes.projectSites(projectId)}>
            <FileIcon />
            <span>Seiten </span>
          </Link>

          <Link
            className={"grid-link"}
            to={routes.projectFooterBuilder(projectId)}
          >
            <FooterIcon />
            <span>Footer </span>
          </Link>

          <Link className={"grid-link"} to={routes.projectBuild(projectId)}>
            <TerminalIcon />
            <span>Build </span>
          </Link>

          {/*<Link className={"grid-link"} to={routes.projectDesign(projectId)}>*/}
          {/*  <SettingsIcon />*/}
          {/*  <span>Design </span>*/}
          {/*</Link>*/}
        </SimpleGrid>
      </AppPage>
    </AppLayout>
  );
}
