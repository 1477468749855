import React, { useEffect } from "react";
import AppLayout from "../../../components/Layout/AppLayout";
import { routes } from "../../../core/constants/routes";
import { Link } from "gatsby";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { SimpleGrid } from "../../../components/Misc/SimpleGrid";
import { FilePlusIcon } from "../../../assets/icons/FilePlus";
import { AppPage } from "../../../components/Misc/AppPage";
import { DragDropContext } from "react-beautiful-dnd";

export default function Sites({
  path,
  organization,
  sites,
  fetchSites,
  projectId,
  project,
  location,
}) {
  useEffect(() => {
    if (organization && project) fetchSites(organization.id, project.id);
  }, [!!organization, !!project]);

  /* SKELETON */
  if (!project)
    return (
      <AppLayout
        path={path}
        showNavigation={true}
        showHeader={true}
        title={"Seiten"}
        location={location}
      >
        <AppPage>
          <SimpleGrid></SimpleGrid>
        </AppPage>
      </AppLayout>
    );

  /* COMPONENT */
  return (
    <AppLayout
      path={path}
      showNavigation={true}
      lastRoute={routes.projectWebsite(projectId)}
      showHeader={true}
      title={"Seiten"}
      subtitle={project.name}
      location={location}
      projectId={project.id}
    >
      <AppPage>
        <SimpleGrid>
          {sites
            ? Object.keys(sites).map((key, index) => {
                const site = sites[key];
                return (
                  <Link
                    className={"grid-link"}
                    to={routes.projectSiteBuilder(projectId, site.id)}
                    key={"page-" + index}
                  >
                    <span>{site.name}</span>
                  </Link>
                );
              })
            : ""}
          <Link
            className={"grid-link"}
            to={routes.projectSiteManager(projectId)}
          >
            <FilePlusIcon />
            <span>Seiten verwalten</span>
          </Link>
        </SimpleGrid>
      </AppPage>
    </AppLayout>
  );
}
