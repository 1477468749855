import React from "react";
import { connect } from "react-redux";
import SiteListComponent from "./SiteList";
import {
  addProjectSite,
  updateProjectSite,
} from "../../../../../core/redux/organizations/action";

const mapStateToProps = (state, props) => {
  const organization = state.organizations[Object.keys(state.organizations)[0]];
  const project = organization.projects[props.projectId];
  return {
    user: state.auth.user,
    organization: organization,
    project: project,
    sitesObject: project.sites,
    selectSite: props.selectSite,
    forceUpdate: props.forceUpdate,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addProjectSite: (orgId, projectId, site) => {
      dispatch(addProjectSite(orgId, projectId, site));
    },
    updateProjectSite: (orgId, projectId, siteId, patch) =>
      dispatch(updateProjectSite(orgId, projectId, siteId, patch)),
  };
};

const SiteList = connect(
  mapStateToProps,
  mapDispatchToProps
)(SiteListComponent);

export default SiteList;
