import styled from "styled-components";
import { Form } from "../../Misc/Form";
import { spacing } from "../../../core/constants/spacing";

export const Style = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  // ... snippet button has high z-index value
  z-index: 11000;

  display: ${(props) => (props.open ? "flex" : "none")};
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;

  background-color: hsla(0, 0%, 0%, 80%);

  .columns {
    display: flex;
    background-color: white;
    max-height: 94%;

    overflow: auto;

    .cl-left,
    .cl-right {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: ${spacing.spaceMd};
      min-height: 600px;
    }

    .cl-left {
      margin: auto;

      display: flex;
      justify-content: center;
      align-items: center;
    }

    .cl-right {
    }
  }

  ${Form} {
    height: 100%;
    padding: 0;
    justify-content: space-between;

    background-color: white;
    border-style: none;

    .form-section {
    }
  }

  @media (max-width: 780px) {
    .columns {
      flex-direction: column;

      .cl-left,
      .cl-right {
        min-height: unset;
      }

      h4 {
        margin-top: ${spacing.spaceSm};
      }
    }

    ${Form} {
      .button-row {
        margin-top: ${spacing.spaceMd};
      }
    }
  }
`;
